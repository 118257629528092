import React from 'react';

function MessageHtml({
  value, className
}) {
  return (
    <div className={className}
      dangerouslySetInnerHTML={{
        __html: value
      }}
    />
  )
}

export default React.memo(MessageHtml);