import React from 'react';
import cls from 'classnames';

function SectionHeading({
  heading, description,
  hr = false,
  className
}) {
  return (
    <div 
      className={cls("pb-5 space-y-2", {
        'border-b border-gray-200': hr
      }, className)}
    >
      {heading && <h3 className="text-lg leading-6 font-medium text-gray-900">
        {heading}
      </h3>}
      {description && <p className="text-sm leading-5 text-gray-500">
        {description}
      </p>}
    </div>
  )
}

export default React.memo(SectionHeading);