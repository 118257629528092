import React from 'react';
import cls from 'classnames';
import Icon from '../Icon';
import { 
  defInputClass, inputErrClass, inputTextSmClass
} from "./index";
import { Input } from 'antd';

const { TextArea } = Input;
const defAutoSize = { minRows: 3, maxRows: 6 };

function LongText({
  classNames,
  value,
  prefix,
  error,
  name,
  type,
  icon,
  autoSize,
  wrapClassNames,
  textSm = true,
  ...props
}) {
  value = value || '';
  return (
    <div className={cls("relative rounded-md shadow-sm", wrapClassNames)}>
      <TextArea 
        autoSize={autoSize || defAutoSize} 
        id={name}
        value={value}
        name={name}
        className={cls("form-input", defInputClass, classNames, {
          [inputTextSmClass]: textSm,
          'cursor-not-allowed': props.disabled,
          'text-gray-400': props.disabled,
          'flex-1 rounded-none rounded-r-md': prefix,
          'pr-10': error || icon,
          [inputErrClass]: error
        })}
        aria-invalid={error ? "true" : null}
        aria-describedby={error ? `${name}-error` : null}
        {...props}
      />
      {
        (error || icon) && <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
          {typeof icon === 'string' ? <Icon 
            className={cls("h-5 w-5", {
              "text-red-500": error,
              "text-gray-400": !error
            })}
            name={cls({
              "s-exclamation-circle": error,
              [icon]: icon
            })} 
          /> : icon}
        </div>
      }
    </div>
  )
}

export default LongText;