import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormItems from '../FormItems';

function getDefaultValues(items) {
  return items.reduce((obj, { name, value, items }) => {
    if (items && items.length) {
      obj = {
        ...obj,
        ...(getDefaultValues(items))
      }
    } else if (value && name) {
      obj[name] = value;
    }
    return obj;
  }, {});
}

function FormSchema({
  items, values = {},
  onSubmit,
  formOptions,
  ...props
}, ref) {
  const options = React.useMemo(() => {
    let _ops = formOptions || {};
    const { defaultValues = {} } = _ops;
    const def = getDefaultValues(items);
    _ops.defaultValues = {
      ...def,
      ...defaultValues,
      ...values
    };
    return _ops;
  }, [formOptions, items, values]);

  const methods = useForm(options);
  const { 
    handleSubmit
  } = methods;
  const _onSubmit = React.useMemo(() => handleSubmit(data => {
    onSubmit && onSubmit(data);
  }), [handleSubmit, onSubmit]);

  React.useImperativeHandle(ref, () => ({
    ...methods,
    submit: _onSubmit
  }), [methods, _onSubmit]);

  return (
    <div {...props}>
      <FormProvider {...methods}>
        <form onSubmit={_onSubmit}>
          <div className="space-y-6">
            <FormItems items={items}/>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default React.memo(React.forwardRef(FormSchema));