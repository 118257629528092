import {
  closeNotificationEvent,
  ERROR
} from 'store/constants';

export function closeNotification(payload) {
  return {
    type: closeNotificationEvent,
    payload
  };
}

export function errorNotification(payload) {
  return {
    type: ERROR,
    payload
  };
}