import Message from "components/Message";
import { connect } from 'react-redux';

export default connect(
  (st, props) => {
    return {
      username: props.isHala ? 'Hala' : st.auth.user.firstName,
      icon: props.isHala ? 'hala' : st.auth.user.icon
    }
  }
)(Message);