import React from 'react';
import MessageInput from './MessageInput';
import { connect } from 'react-redux';
import { inputSection } from 'store/constants';
import FilePicker from 'components/FilePicker';

const layout = 'max-w-3xl mx-auto w-full pb-2';

function MessageInputType({
  type, ...props
}) {
  switch(type) {
    case 'form':
    case 'form.v2':
      return (
        <div id={inputSection} className={layout}></div>
      );
    case 'filePicker':
      return (
        <div className="max-w-3xl mx-auto w-full">
          <FilePicker {...props}/>
        </div>
      );
    default:
      return (
        <div className={`pt-2 ${layout}`}>
          <MessageInput/>
        </div>
      );
  }
}

export default connect(
  state => {
    let msg = {};
    if (state.messages.length) {
      msg = state.messages[state.messages.length - 1];
      if (msg.type === 'devData') {
        msg = state.messages[state.messages.length - 2];
      }
    }
    return msg
  }
)(MessageInputType);