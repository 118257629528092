import React from 'react';
import cls from 'classnames';
import ShortMessage from '../../ShortMessage';
import Panel from 'components/Panel';

function MessageGrid({
  items,
  cols = 3,
  rows = 3,
  gap = 3,
  className
}) {
  return (
    <div
      className={cls("grid grid-flow-col p-4 h-screen-4/5 bg-gray-100", className, {
        [`grid-cols-${cols}`]: cols,
        [`grid-rows-${rows}`]: rows,
        [`gap-${gap}`]: gap
      })}
    >
      {
        items.map(({
          row, col, items = [], className, 
          padding = 4
        }, key) => (
          <Panel
            key={key}
            className={cls(className, {
              [`p-${padding}`]: padding,
              [`row-span-${row}`]: row,
              [`col-span-${col}`]: col
            })}
          >
            <div className="flex items-center h-full w-full">
              <div className={cls("overflow-y-scroll max-h-full w-full", {
                'h-full': items.find(el => ['chart', 'PieChart'].includes(el.type)) ? true : false
              })}>
                {
                  items.map((props, key) => (
                    <ShortMessage key={key} {...props} />
                  ))
                }
              </div>
            </div>
          </Panel>
        ))
      }
    </div>
  )
}

export default React.memo(MessageGrid);