import React from 'react';
import Button from 'components/Button';
import Icon from 'components/Icon';
import { useForm } from 'react-hook-form';
import { sendMessage } from 'actions/input';
import { connect } from 'react-redux';
import useAudioInput from 'hooks/useAudioInput'
import Recording from './Recording';
import cls from 'classnames'

function WellcomeForm({
  sendMessage
}) {
  const {
    isRecording, isLoading,
    isProcessing, isPreparing, placeholder,
    btnProps, hasFocus, onFocus, onBlur
  } = useAudioInput(sendMessage);
  
  const { handleSubmit, register, reset, watch } = useForm();
  const value = watch('message');

  const onSubmit = React.useCallback(({ message }) => {
    sendMessage(message);
    reset();
  }, [sendMessage, reset]);

  const canuseMic = React.useMemo(() => value || hasFocus ? false : true, [value, hasFocus]);
  
  return (
    <form
      className="mt-8 sm:flex"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex w-full">
        {
          isLoading? (
            <div className="relative flex items-center justify-center">
              { isPreparing || isProcessing ? <Icon className="left-3 w-5 h-5 absolute text-red-600" name="oval"/> : null }
              { isRecording && <Recording className="left-3" /> }
            </div>
          ) : null
        }
        <input
          name="message"
          aria-label="message"
          type="text"
          required
          disabled={isLoading}
          className={cls("appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out", {
            "pl-10": isLoading
          })}
          placeholder={placeholder || 'Enter your message ...'}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={register()}
        />
      </div>
      <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <div {...(canuseMic ? btnProps : {})}>
          <Button
            type={!canuseMic ? "submit" : "button"}
            red={isLoading}
            primary={!isLoading}
            size="2lg"
            className="w-full"
          >
            <Icon className="h-6 w-6" name={canuseMic ? "o-microphone" : "o-check"} />
          </Button>
        </div>
      </div>
    </form>
  )
}

export default connect(null, {
  sendMessage
})(WellcomeForm);