import React from 'react';
import ReactPlayer from 'react-player/lazy';

function VideoMessage({
  url,
  options = {}
}) {
  return (
    <div
      className="relative w-full"
      style={{
        paddingTop: "56.25%"
      }}
    >
      <ReactPlayer
        className="absolute top-0 left-0"
        width="100%"
        height="100%"
        url={url}
        {...options}
      />
    </div>
  );
}

export default React.memo(VideoMessage);