import React from 'react';
import { ReactComponent as sExclamationCircle } from './heroicons/solid/exclamation-circle.svg';
import { ReactComponent as sLockClosed } from './heroicons/solid/lock-closed.svg';
import { ReactComponent as oval } from './oval.svg';
import { ReactComponent as oExclamationCircle } from './heroicons/outline/exclamation-circle.svg';
import { ReactComponent as oHome } from './heroicons/outline/home.svg';
import { ReactComponent as oUserGroup } from './heroicons/outline/user-group.svg';
import { ReactComponent as sChRight } from './heroicons/solid/chevron-right.svg';
import { ReactComponent as sChLeft } from './heroicons/solid/chevron-left.svg';
// import { ReactComponent as sChevronUp } from './heroicons/solid/chevron-up.svg';
// import { ReactComponent as sChevronDown } from './heroicons/solid/chevron-down.svg';
// import { ReactComponent as oChevronRight } from './heroicons/outline/chevron-right.svg';
// import { ReactComponent as oChevronLeft } from './heroicons/outline/chevron-left.svg';
import { ReactComponent as oChevronUp } from './heroicons/outline/chevron-up.svg';
import { ReactComponent as oChevronDown } from './heroicons/outline/chevron-down.svg';
import { ReactComponent as oChevronRight } from './heroicons/outline/chevron-right.svg';
import { ReactComponent as oHashtag } from './heroicons/outline/hashtag.svg';
import { ReactComponent as oAtSymbol } from './heroicons/outline/at-symbol.svg';
import { ReactComponent as oGridAdd } from './heroicons/outline/view-grid-add.svg';
import { ReactComponent as oSwitchHorizontal } from './heroicons/outline/switch-horizontal.svg';
import { ReactComponent as sCheck } from './heroicons/solid/check.svg';
import { ReactComponent as sX } from './heroicons/solid/x.svg';
import { ReactComponent as oSelector } from './heroicons/outline/selector.svg';
import { ReactComponent as sSelector } from './heroicons/solid/selector.svg';
import { ReactComponent as oMicrophone } from './heroicons/outline/microphone.svg';
import { ReactComponent as oCheck } from './heroicons/outline/check.svg';
import { ReactComponent as oPaperClip } from './heroicons/outline/paper-clip.svg';
import { ReactComponent as oCode } from './heroicons/outline/code.svg';
import { ReactComponent as oMenuAlt3 } from './heroicons/outline/menu-alt-3.svg';
import { ReactComponent as oCog } from './heroicons/outline/cog.svg';
import { ReactComponent as oLogout } from './heroicons/outline/logout.svg';
import { ReactComponent as oSupport } from './heroicons/outline/support.svg';
import { ReactComponent as oShieldCheck } from './heroicons/outline/shield-check.svg';
import { ReactComponent as oPencilAlt } from './heroicons/outline/pencil-alt.svg';
import { ReactComponent as sPencilAlt } from './heroicons/solid/pencil-alt.svg';
import { ReactComponent as oPlus } from './heroicons/outline/plus.svg';
import { ReactComponent as sPlus } from './heroicons/solid/plus.svg';
import { ReactComponent as sCheckCircle } from './heroicons/solid/check-circle.svg';
import { ReactComponent as sXCircle } from './heroicons/solid/x-circle.svg';
import { ReactComponent as sDotsVertical } from './heroicons/solid/dots-vertical.svg';
import { ReactComponent as sDotsHorizontal } from './heroicons/solid/dots-horizontal.svg';
import { ReactComponent as sDuplicate } from './heroicons/solid/duplicate.svg';
import { ReactComponent as sTrash } from './heroicons/solid/trash.svg';
import { ReactComponent as oRefresh } from './heroicons/outline/refresh.svg';
import { ReactComponent as sArrowUp } from './heroicons/solid/arrow-up.svg';
import { ReactComponent as sArrowDown } from './heroicons/solid/arrow-down.svg';


const icons = {
    's-arrow-down': sArrowDown,
    's-arrow-up': sArrowUp,
    's-exclamation-circle': sExclamationCircle,
    's-lock-closed': sLockClosed,
    'oval': oval,
    'o-exclamation-circle': oExclamationCircle,
    'o-home': oHome,
    'o-user-group': oUserGroup,
    's-ch-right': sChRight,
    's-ch-left': sChLeft,
    'o-chevron-up': oChevronUp,
    'o-chevron-down': oChevronDown,
    'o-chevron-right': oChevronRight,
    'o-hashtag': oHashtag,
    'o-at-symbol': oAtSymbol,
    'o-grid-add': oGridAdd,
    'o-switch-horizontal': oSwitchHorizontal,
    's-check': sCheck,
    's-x': sX,
    'o-selector': oSelector,
    's-selector': sSelector,
    'o-microphone': oMicrophone,
    'o-paper-clip': oPaperClip,
    'o-code': oCode,
    'o-menu-alt-3': oMenuAlt3,
    'o-cog': oCog,
    'o-logout': oLogout,
    'o-support': oSupport,
    'o-shield-check': oShieldCheck,
    'o-pencil-alt': oPencilAlt,
    'o-check': oCheck,
    's-pencil-alt': sPencilAlt,
    'o-plus': oPlus,
    's-plus': sPlus,
    's-check-circle': sCheckCircle,
    's-x-circle': sXCircle,
    's-dots-vertical': sDotsVertical,
    's-dots-horizontal': sDotsHorizontal,
    's-duplicate': sDuplicate,
    's-trash': sTrash,
    'o-refresh': oRefresh
};

function Icon({
    name, ...props
}) {
    const Comp = React.useMemo(() => icons[name], [name]);
    if (!Comp) return null;
    return <Comp {...props} />;
}

export default React.memo(Icon);