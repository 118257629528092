import cls from 'classnames';
import { columnId as detailedColumnId } from './useRowDetail';
import { columnId as selectColumnId } from './useRowSelectExtend';
import { columnId as actionsColumnId } from './useRowEdit';

const coldIds = [
  detailedColumnId, selectColumnId,
  actionsColumnId
];

export const defaultStyles = {
  textColor: 'gray-500',
  textSize: "sm",
  leading: "5",
  textBold: null,
  cls: "", //whitespace-no-wrap
  background: null,
  textAligin: "left",
  paddingLeft: 6,
  paddingRight: 6,
  paddingTop: 4,
  paddingBottom: 4
}

function getDefault(isCol) {
  const def = {
    ...defaultStyles
  };
  if (isCol) {
    def.textSize = 'xs';
    def.leading = '4';
    def.textBold = 'medium';
    def.cls = 'uppercase tracking-wider border-gray-200';
    def.background = "cool-gray-50";
    def.paddingTop = 3;
    def.paddingBottom = 3;
    def.borderBottom = true;
    def.borderTop = true;
  }
  return def;
}

function GetProps({
  className, ...props
}, { instance, column, cell }) {
  const isCol = column ? true : false;
  if (!isCol) column = cell.column;
  const isFirst = instance.columns[0].id === column.id;
  const last = instance.columns[instance.columns.length - 1];
  const isLast = last.id === column.id;
  let defCls = getDefault(isCol);

  // if row isTotal add styles to each their colls
  if (!isCol && cell.row.original.isTotal) {
    defCls.cls += ` border-t-2 border-cool-gray-300 text-cool-gray-900 font-medium`;
  }

  // if last column is currency make it bolder
  if (isLast && !isCol && last.renderer && last.renderer.type === "currency") {
    defCls.textColor = "cool-gray-900";
    defCls.textBold = "medium";
    defCls.textSize = "base";
  }

  // if 2 columns remove space to avoid horisontal scroll
  if (instance.columns.length === 2) {
    defCls.paddingLeft = 1;
    defCls.paddingRight = 1;
  }

  switch (instance.uiStyle) {
    case "panelList":
      if (isFirst) {
        defCls.paddingLeft = 0;
      } else if (isLast) {
        defCls.paddingRight = 0;
        defCls.textAligin = 'right';
      }
      defCls.paddingTop = defCls.paddingBottom = isCol ? 1 : 2;
      defCls.background = false;
      defCls.borderTop = false;
      break;
    default:
      break;
  }

  if (column.uiStyle) {
    if (isCol) {
      defCls = Object.assign({}, defCls, {
        textAligin: column.uiStyle.textAligin || defCls.textAligin,
        paddingLeft: column.uiStyle.paddingLeft || defCls.paddingLeft,
        paddingRight: column.uiStyle.paddingRight || defCls.paddingRight,
        paddingTop: column.uiStyle.paddingTop || defCls.paddingTop,
        paddingBottom: column.uiStyle.textAligin || defCls.paddingBottom
      });
    } else {
      defCls = Object.assign({}, defCls, column.uiStyle);
    }
  }

  if (coldIds.includes(column.id)) {
    defCls.cls += ' w-5';
  }

  return {
    ...props,
    className: cls(defCls.cls, {
      [`bg-${defCls.background}`]: defCls.background,
      [`pl-${defCls.paddingLeft}`]: defCls.paddingLeft,
      [`pb-${defCls.paddingBottom}`]: defCls.paddingBottom,
      [`pr-${defCls.paddingRight}`]: defCls.paddingRight,
      [`pt-${defCls.paddingTop}`]: defCls.paddingTop,
      [`text-${defCls.textAligin}`]: defCls.textAligin,
      [`text-${defCls.textColor}`]: defCls.textColor,
      [`text-${defCls.textSize}`]: defCls.textSize,
      [`leading-${defCls.leading}`]: defCls.leading,
      [`font-${defCls.textBold}`]: defCls.textBold,
      'border-b': defCls.borderBottom,
      'border-t': defCls.borderTop
    }, className, column.className)
  };
}


function BodyProps({
  className, ...props
}, { instance }) {
  let o = {
    borderBottom: true
  }

  switch (instance.uiStyle) {
    case "panelList":
      o.borderBottom = false;
      break;
    default:
      break;
  }

  return {
    ...props,
    "x-max": "1",
    className: cls(
      className,
      "bg-white divide-y divide-gray-200 border-gray-200", {
      "border-b": o.borderBottom
    })
  };
}

export default function (hooks) {
  hooks.getHeaderProps.push(GetProps);
  hooks.getCellProps.push(GetProps);
  hooks.getTableBodyProps.push(BodyProps);
}