import React from 'react';
// import Table from 'components/Table';
// import DefaultCell, {CellWrap} from 'components/Table/DefaultCell';
// import {
//   useTable, usePagination,
//   useFlexLayout, useRowSelect
// } from 'react-table';
// import useDetailedView from 'components/Table/hooks/useDetailedView';
// import useSelectColumn from 'components/Table/hooks/useSelectColumn';
// import useRowSelection from 'components/Table/hooks/useRowSelection';
// import DescriptionList from 'components/DescriptionList';
// import dataParser from 'utils/parser';
import MsgBetaTable from 'components/Table/Beta';

// const defaultColumn = {
//   Cell: DefaultCell,
//   CellWrap: CellWrap
// };

// function mergeColumns(data, columns) {
//   if (!columns || !columns.length) {
//     return data;
//   }
//   return data.map(el => {
//     columns.forEach(({accessor, ...rest}) => {
//       if (el.accessor && el.accessor === accessor) {
//         el = {
//           ...el,
//           ...rest
//         }
//       }
//     });
//     if (el.columns) {
//       el.columns = mergeColumns(el.columns, columns);
//     }
//     return el;
//   });
// }

// function MsgTable({
//   columns, 
//   data = [], parseData,
//   hiddenColumns = [], rowSelection, 
//   canAddRow, canEditRow, canDeleteRow, addRowText,
//   itemForm,
//   onChange
// }) {

//   let parsed = React.useMemo(() => {
//     if (parseData) {
//       return dataParser(parseData, data);
//     }
//     return null;
//   }, [data, parseData]);

//   if (parsed && parsed.data) { 
//     data = parsed.data;
//   }

//   const _columns = React.useMemo(() => {
//     let c = columns;
//     if (parsed && parsed.columns) {
//       c = mergeColumns(parsed.columns, c);
//     }
//     c = c.concat();
//     return c.map(el => {
//       let ext = {};
//       if (el.render) {
//         ext.renderer = el.render;
//       }
//       return {
//         ...el,
//         ...ext
//       };
//     });
//   }, [columns, parsed]);

//   const plugins = React.useMemo(() => {
//     let pl = [];
//     if (
//       (hiddenColumns && hiddenColumns.length) 
//       || canAddRow || canEditRow || canDeleteRow
//     ) {
//       pl.push(useDetailedView);
//     }
//     if (rowSelection) {
//       pl.push(useRowSelect, useSelectColumn, useRowSelection);
//     }
//     return pl;
//   }, [
//     hiddenColumns, rowSelection, 
//     canAddRow, canEditRow, canDeleteRow
//   ]);

//   const tableProps = useTable({
//     columns: _columns,
//     data,
//     initialState: {
//       hiddenColumns: hiddenColumns || []
//     },
//     total: data.length,
//     DetailedView: DescriptionList,
//     onChange,
//     canAddRow, 
//     addRowText,
//     itemForm,
//     defaultColumn
//   },
//     usePagination,
//     useFlexLayout,
//     ...plugins
//   );

//   return (
//     <div
//       className="w-full mx-auto border-l border-r border-gray-200"
//     >
//       <Table
//         data={tableProps}
//       />
//     </div>
//   );
// }

function MsgTableVer({
  beta, 
  id, isHala, icon, username, 
  ...props
}) {
  // if (beta) {
    return <MsgBetaTable {...props} />;
  // }
  // return <MsgTable {...props} />;
}

export default React.memo(MsgTableVer)