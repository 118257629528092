import React from 'react';
import MessageContainer from './MessageContainer';
import MessageWithLogo from './MessageWithLogo';
import ShortMessage from './ShortMessage';

function Message({
  scrollIntoView,
  withLogo,
  ...props
}) {
  const {size} = props;
  return (
    <MessageContainer 
      scrollIntoView={scrollIntoView}
      size={size}
      type={props.type}
    >
      {
        withLogo
          ? <MessageWithLogo {...props} />
          : <ShortMessage {...props} />
      }
    </MessageContainer>
  )
}

export default React.memo(Message);