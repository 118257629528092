import React from 'react';
import CompanyLogo from 'components/CompanyLogo';
import WellcomeButtons from "./WellcomeButtons";
import WellcomeForm from "./WellcomeForm";

function WellcomeSection() {
  return (
    <section className="h-screen w-full flex items-center justify-center max-w-screen-sm mx-auto">
      <div className="w-full p-4">
        <CompanyLogo 
          withText 
          size="16"
          className="text-indigo-600 justify-center mb-16"
          textClass="text-gray-600"
        />
        <WellcomeForm/>
        <div className="my-8 text-center text-sm text-gray-500 font-light tracking-wider"> or start with </div>
        <WellcomeButtons />
      </div>
    </section>
  )
}

export default React.memo(WellcomeSection);