import React from 'react';
import cls from 'classnames';

function DescriptionList({
  values, title, description
}) {

  values = React.useMemo(() => {
    if (!values) return [];
    if (Array.isArray(values)) {
      return values;
    }
    if (typeof values === 'object') {
      return Object.keys(values).map(key => ({
        key,
        value: values[key]
      }))
    }
    return [];
  }, [values]);

  return (
    <div className="bg-white overflow-hidden">
      {(title || description) && <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
        {title && <h3 className="text-lg leading-6 font-medium text-gray-900">
          {title}
        </h3>}
        {description && <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
          {description}
        </p>}
      </div>}
      <div className="px-4 py-5 sm:p-0">
        <dl>
          {
            values.map(({ key, value }, _key) => (
              <div 
                key={_key}
                className={cls("sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5",{
                  "mt-8 sm:mt-0 sm:border-t sm:border-gray-200": _key > 0
                })}
              >
                <dt className="text-sm leading-5 font-medium text-gray-500">
                  { key }
                </dt>
                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                  { value }
                </dd>
              </div>
            ))
          }
        </dl>
      </div>
    </div>
  )
}

export default React.memo(DescriptionList);