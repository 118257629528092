import { compile } from 'utils/hb';
import 'twix';
import moment from 'moment';
import React from 'react';

const totalTpl = compile(`$ <hl>numeral val format="0,0.[00]"</hl>`);
const yTpl = compile(`$ <hl>numeral val format="0.[0]a"</hl>`);

function __findRowItem(data, value) {
  if (data && data.length && data[0].value === value) {
    return data.map(el => Number(el.value));
  }
  return null;
}

function findRow(data, value, def = null) {
  if (def || !data) return def;
  return data.reduce((res, { ColData, Rows = {}, Summary = {} }) => {
    return __findRowItem(ColData, value)
      || __findRowItem(Summary.ColData, value)
      || findRow(Rows.Row, value, res);
  }, def);
}

function genColumns(data, key = '0') {
  const cols = [...data];
  cols.splice(0, 1);
  cols.splice(cols.length - 1, 1);
  return cols.reduce((res, { ColTitle }) => {
    res.push({
      [`name${key}`]: ColTitle
    });
    return res;
  }, []);
}

const xaxis = {
  tickFormatter: val => val.split(',')[0]
};

const yaxis = {
  tickFormatter: val => yTpl({ val })
};

const tooltip = {
  formatter: val => totalTpl({ val })
};

const legend = {
  formatter:(value) => {
    return (
      <span className="text-sm leading-5 font-light text-gray-500">{value}</span>
    );
  }
}

export default function intuilChart(data, options) {
  let columns = [];
  let currency = null;
  if (Array.isArray(data)) {
    columns = data.reduce((r, d, k) => {
      const cl = genColumns(d.Columns.Column, k);
      let n = r.length;
      if (cl.length > n) n = cl.length;
      let nr = [];
      for (var i = 0; i < n; i++) {
        nr[i] = {
          ...r[i],
          ...cl[i]
        }
      }
      return nr;
    }, []);
    currency = data[0]?.Header?.Currency;
  } else {
    columns = genColumns(data.Columns.Column);
    currency = data?.Header?.Currency;
  }
  let Header = {};
  const res = options.charts.reduce((res, chart, k) => {
    let ind = data?.Rows?.Row;
    let { dataIndex , ...ch} = chart;
    Header = data?.Header;
    if (Array.isArray(data)) {
      ind = data[dataIndex || 0].Rows.Row;
      Header = data[dataIndex || 0]?.Header;
    }
    const _data = findRow(ind, chart.name) || [];
    _data.shift();
    ch.total = _data.pop() || 0;
    ch.dataKey = `val${k}`;
    const m = moment(Header.StartPeriod).twix(Header.EndPeriod, {allDay: true});
    ch.name = `${ch.name}(${m.format({implicitYear: false})})`
    res.charts.push(ch);
    _data.forEach((el, kk) => {
      res.data[kk][ch.dataKey] = el;
    });
    return res;
  }, {
    data: columns,
    charts: []
  });

  let _xaxis = {...xaxis};
  if (Header.SummarizeColumnsBy !== 'Year') {
    _xaxis = {
      ...xaxis,
      tickFormatter: val => {
        val = val.split(',')[0].trim();
        const m = val.match(/\b\d{4}\b/);
        if (m && m[0]) {
          val = val.replace(m[0],'').trim();
        }
        return val;
      }
    };
  }
  
  if (options.compare && res.charts.length === 2) {
    options.compare = {
      increased: res.charts[0].total > res.charts[1].total,
      percentage: Math.abs(Math.round((res.charts[0].total-res.charts[1].total)/(res.charts[1].total/100)))
    }
  }
  
  return {
    ...options,
    currency,
    xaxis: {
      ..._xaxis,
      ...options.xaxis
    },
    yaxis: {
      ...yaxis,
      ...options.yaxis
    },
    tooltip: {
      ...tooltip,
      ...options.tooltip
    },
    legend: {
      ...legend,
      ...options.legend
    },
    ...res
  };
}