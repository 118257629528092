import { socket } from './socket';
import moment from 'moment';
import {
  ioUserMessage,
  CLIENT_MESSAGE, SERVER_MESSAGE,
  CLOSE_FORM, SUBMIT_FORM, REMOVE_MESSAGE,
  CLIENT_IMAGE_MESSAGE, CLIENT_IMAGE_MESSAGE_ERROR,
  REFRESH
} from 'store/constants';
import { v4 as uuid } from 'uuid';
import Storage from '@aws-amplify/storage';

function userMessage(text, context) {
  socket.emit(ioUserMessage, JSON.stringify({
    text,
    date: moment().format(),
    context
  }));
}

function msgType(type) {
  return dispatch => {
    dispatch({
      type: SERVER_MESSAGE,
      payload: {
        data: [{
          type: "markdown",
          file: `msg-type-${type}`,
          // content: '# Live demo'
        }],
        date: moment().format(),
        id: uuid(),
        isHala: true
      }
    });
  }
}

function processCommand(message) {
  return dispatch => {
    if (message && message.charAt(0) === '/') {
      if (message.indexOf('/msg-type') === 0) {
        dispatch(msgType(message.split('/msg-type')[1].trim()));
        return true;
      }
    }
    return false;
  }
}

export const removeMessage = payload => ({
  type: REMOVE_MESSAGE,
  payload
})

export const sendMessage = message => {
  return dispatch => {
    if (!message) {
      return;
    }

    if (dispatch(processCommand(message))) {
      return;
    }

    dispatch({
      type: CLIENT_MESSAGE,
      payload: message
    });

    userMessage(message);
  }
}

export const submitForm = (messageId, data) => {
  return dispatch => {
    userMessage('Form Result', {
      formCanceled: false,
      formResult: data
    });
    dispatch({
      type: SUBMIT_FORM,
      payload: {
        data, messageId
      }
    })
  }
}

export const closeForm = payload => {
  return dispatch => {
    userMessage('Form Canceled', {
      formCanceled: true,
      formResult: null
    });
    dispatch({
      type: CLOSE_FORM,
      payload
    })
  }
}

export const sendFileMessage = (file, context) => {
  return dispatch => {
    const reader = new FileReader();
    reader.onload = () => {
      const url = reader.result;
      dispatch({
        type: CLIENT_IMAGE_MESSAGE,
        payload: url
      });
    };
    reader.readAsDataURL(file);

    const fileKey = uuid();
    Storage.put(fileKey, file, {
      contentType: file.type
    }).then(() => {
      userMessage('file Key', {
        fileKey,
        type: 'filePicker',
        __process: context.process ? context.process : null
      })
    })
    .catch(err => {
      dispatch({
        type: CLIENT_IMAGE_MESSAGE_ERROR,
        payload: err.message
      });
    });
  }
}

export const refresh = () => {
  return dispatch => {
    socket.emit('command', 'clearHistory');
    dispatch({
      type: REFRESH
    });
  }
}