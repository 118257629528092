import React from 'react';
import SizeContainer from 'components/SizeContainer';
import cls from 'classnames';

function FormSection({
  className, grid, children, size = 'sm'
}) {
  const classes = React.useMemo(() => {
    let cstr = 'mt-6';
    if (typeof grid !== 'undefined') {
      let size = 6;
      if (grid !== true) {
        size = grid;
      }
      cstr += ` grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-${size}`;
    }
    return cstr;
  }, [grid]);

  return (
    <SizeContainer 
      size={size}
      className={cls(className, classes)}
    >
      { children }
    </SizeContainer>
  )
}

export default React.memo(FormSection);