import React from 'react';
import SelectRowCheckbox from '../SelectRowCheckbox';

export const columnId = 'selection';

function useInstance(instance) {
  const {
    selectedFlatRows,
    onChange
  } = instance;

  React.useEffect(() => {
    onChange && onChange({
      type: "select",
      data: selectedFlatRows.map(({ original }) => original)
    });
  }, [selectedFlatRows, onChange])
}

function addColumns(columns) {
  return [{
    id: columnId,
    Header: ({ getToggleAllRowsSelectedProps, data, loading }) => {
      const disabled = loading || !data.length || (data.length === 1 && data[0].isNew);
      const props = getToggleAllRowsSelectedProps();
      return <SelectRowCheckbox
        className="w-5 h-5"
        disabled={disabled}
        {...props}
      />
    },
    Cell: ({ row }) => {
      return <SelectRowCheckbox
        className="w-5 h-5"
        {...row.getToggleRowSelectedProps()}
      />
    }
  },
  ...columns,
  ]
}

export default function (hooks) {
  hooks.useInstance.push(useInstance);
  hooks.visibleColumns.push(addColumns);
}