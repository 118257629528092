import { useCallback, useState, useRef } from "react";

export default function useLongPress(onStart, onStop) {
    const timeout = useRef();
    const [isPressed, setPressed] = useState(false);

    const start = useCallback(e => {
        if (isPressed) return;
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(() => {
            setPressed(true);
            onStart(e)
        }, 300);
    }, [onStart, isPressed]);

    const stop = useCallback(e => {
        timeout.current && clearTimeout(timeout.current);
        if (!isPressed) return;
        setPressed(false);
        onStop(e)
    }, [onStop, isPressed]);

    return {
        onMouseDown: e => start(e),
        onTouchStart: e => start(e),
        onMouseUp: e => stop(e),
        onMouseLeave: e => stop(e),
        onTouchEnd: e => stop(e)
    };
};