import { useCallback, useEffect } from 'react';
import cls from 'classnames';
import { useMountedLayoutEffect } from "react-table";

function CellProps(props, {cell}) {
  const { editCell } = cell.row;
  const id = cell.column.id;

  if (cell.isEdit) { 
    props.className = '';
  } else {
    props.className += ' bg-white hover:bg-gray-50 transition duration-300 ease-in-out cursor-pointer';
  }

  const onClick = useCallback(() => editCell(id), [editCell, id]);

  props.onClick = cell.isEdit ? null : onClick;
  return props;
}

function TableProps({
  className = '',
  ...props
}) {
  return {
    ...props,
    className: cls(className, 'table-fixed')
  };
}

function PrepareRow(row, { instance }) {
  const { values, id } = row;
  const { setEditableCell, state, saveRow, rows } = instance;
  row.editCell = cellId => setEditableCell(cellId, id);
  row.saveRow = data => saveRow(id, data);
  row.isEmpty = Object.keys(values).every(key => typeof values[key] === 'undefined');
  row.isEdit = state.editableRowId === row.id;
  if (row.isEdit && state.editableCellId) {
    row.cells.map(el => {
      if (el.column.id === state.editableCellId) {
        el.cellRender = 'EditableCell';
        el.isEdit = true;
        const indx = parseInt(id);
        el.goUp = indx > 0 ? () => setEditableCell(el.column.id, `${indx - 1}`) : null;
        el.goDown = rows[indx + 1] ? () => setEditableCell(el.column.id, `${indx + 1}`) : null;
        let {prevId, nextId} = row.cells.reduce((r, el, key, all) => {
          if (el.column.id === state.editableCellId) {
            return {
              prevId: key > 0 ? all[key-1]?.column.id : null,
              nextId: all[key+1] ? all[key+1]?.column.id : null
            }
          }
          return r;
        }, {});
        el.goLeft = prevId ? () => setEditableCell(prevId, id) : null;
        el.goRigth = nextId ? () => setEditableCell(nextId, id) : null;
      }
      return el;
    })
  } else if (row.isEmpty) {
    row.cellRender = 'EditableEmptyCell'
  }
}

const actions = {
  setEditableCell: 'setEditableCell',
  closeEditable: 'closeEditable',
  init: 'init'
};

function reducer(state, action) {
  switch (action.type) {
    case actions.setEditableCell:
      return {
        ...state,
        editableRowId: action.rowId,
        editableCellId: action.cellId
      };
    case actions.closeEditable:
    case actions.init:
      return {
        ...state,
        editableRowId: null,
        editableCellId: null
      };
    default:
      return state;
  }
}

function UseInstance(instance) {
  const {
    dispatch,
    onChange,
    data,
    rows,
    state: {editableRowId}
  } = instance;

  useMountedLayoutEffect(() => {
    dispatch({ type: actions.closeEditable });
  }, [dispatch]);

  useEffect(() => {
    if (editableRowId && rows.length && rows[rows.length-1]?.id === editableRowId) {
      onChange && onChange({
        type: "add",
        data: {}
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableRowId]);

  useEffect(() => {
    let tId = null;
    if (!data || !data.length) {
      tId = setTimeout(() => {
        onChange && onChange({
          type: "add",
          data: {}
        })
      }, 0);
    }
    if (tId) {
      return () => clearTimeout(tId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const setEditableCell = useCallback(
    (cellId, rowId) => dispatch({ type: actions.setEditableCell, rowId, cellId }),
    [dispatch]
  )

  const closeEditable = useCallback(
    () => dispatch({ type: actions.closeEditable }),
    [dispatch]
  )

  const saveRow = useCallback((id, data) => {
    onChange && onChange({
      type: "edit",
      id, data
    });
  }, [onChange]);

  Object.assign(instance, {
    setEditableCell, closeEditable,
    saveRow
  });
}

export default function (hooks) {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(UseInstance);
  hooks.getCellProps.push(CellProps);
  hooks.prepareRow.push(PrepareRow);
  hooks.getTableProps.push(TableProps)
}