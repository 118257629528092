import React from 'react';
import Icon from 'components/Icon';
import Transition from 'components/Transition';
import cls from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { signOut } from 'actions/auth';
import { refresh } from 'actions/input';

function MenuItem({
  name, icon, to = '/', onClick
}) {
  return (
    <Link 
      to={to}
      onClick={onClick}
      className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" 
      role="menuitem">
        <Icon 
          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500"
          name={icon}
        />
      { name }
    </Link>         
  )
}

function Header({
  email, signOut, refresh
}) {
  const [show, setShow] = React.useState(false);
  const toggle = React.useCallback(() => setShow(st => !st), []);
  const close = React.useCallback(() => setShow(false), []);
  const ref = useOnClickOutside(close);

  return (
    <div ref={ref} className="relative inline-block text-left right-4 z-30">
      <div className="pt-4 flex">
        <button 
          onClick={refresh}
          className="mr-2 text-gray-400 flex items-center  hover:text-gray-600 focus:outline-none focus:text-gray-600"
        >
          <Icon className="h-7 w-7" name="o-refresh" />
        </button>
        <button 
          onClick={toggle}
          className={cls("flex items-center  hover:text-gray-600 focus:outline-none focus:text-gray-600", {
            'text-gray-400': !show,
            'text-gray-600': show
          })} 
          aria-label="Options" 
          id="header-menu" 
          aria-haspopup="true" 
          aria-expanded="true"
        >
          <Icon className="h-7 w-7" name="o-menu-alt-3" />
        </button>
      </div>
      <Transition
        show={show}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg">
          <div 
            className="rounded-md bg-white shadow-xs" 
            role="menu" 
            aria-orientation="vertical" 
            aria-labelledby="header-menu"
          >
            <div className="px-4 py-3">
              <p className="text-sm leading-5">
                Signed in as
              </p>
              <p className="text-sm leading-5 font-medium text-gray-900 truncate">
                {email}
              </p>
            </div>
            <div className="border-t border-gray-100"></div>
            <div className="py-1">
              <MenuItem name="Account settings" icon="o-pencil-alt"/>
              <MenuItem name="Support" icon="o-support"/>
              <MenuItem name="License" icon="o-shield-check"/>
            </div>
            <div className="border-t border-gray-100"></div>
            <div className="py-1">
              <MenuItem onClick={signOut} name="Sign out" icon="o-logout"/>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}

export default connect(
  st => ({
    email: st.auth.user.email
  }),
  { signOut, refresh }
)(Header);