import React from 'react';

function MessageText({
  value
}) {
  return (
    <>
      {(typeof value === 'string' ? [value] : value).map((mssg, i) => (
        <div
          key={i}
          className="flex text-base leading-6 text-gray-700"
          dangerouslySetInnerHTML={{ 
            __html: typeof mssg === 'number' ? `${mssg}` : mssg
          }}
        />
      ))}
    </>
  )
}

export default React.memo(MessageText);