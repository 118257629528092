import { useMemo, useState } from 'react';

const defState = {
  tableOverflow: true
}

function useInstance(instance) {
  const {
    canAddRow,
    onChange,
    addRowText,
    itemForm, slideover,
    visibleColumns
  } = instance;
  const [state, setState] = useState(defState);

  const extend = useMemo(() => {
    return {
      context: {
        canAddRow,
        onChange,
        addRowText,
        itemForm, slideover,
        visibleColumns,
        setState,
        ...state
      }
    }
  }, [
    canAddRow,
    onChange,
    addRowText,
    itemForm, slideover,
    visibleColumns,
    setState,
    state
  ]);

  Object.assign(instance, extend);
}

export default function (hooks) {
  hooks.useInstance.push(useInstance);
}