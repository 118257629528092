import React, { Suspense } from 'react';
import MessageText from './Type/Text';
import MessageLoader from 'components/BounceSpinner';
import DevData from './Type/DevData';
import Form from './Type/Form';
import BarChart from './Type/BarChart';
import PieChart from './Type/PieChart';
import Table from './Type/Table';
import QuickReply from "./Type/QuickReply";
import VideoMessage from "./Type/VideoMessage";
import ImageMessage from "./Type/ImageMessage";
import Button from 'components/Button';
import MessageHtml from './Type/Html';
import Grid from './Type/Grid';
import Chart from './Type/Chart';
import SectionHeading from 'components/SectionHeading';
import DescriptionList from 'components/DescriptionList';
import CompareStats from 'components/CompareStats';

// const Markdown = React.lazy(() => import('./Type/Markdown'));

function ShortMessage({
  type,
  ...props
}) {
  switch (type) {
    case 'button':
      return <Button {...props.props}/>
    case 'text':
      return <MessageText {...props}/>;
    case 'html':
      return <MessageHtml {...props}/>;
    case 'loader':
      return <MessageLoader/>;
    case 'devData':
      return <DevData {...props} />;
    case 'form':
    case 'form.v2':
      return <Form {...props} />;
    case 'grid':
      return <Grid {...props} />;
    case 'BarChart':
      return <BarChart {...props} />;
    case 'chart':
      return <Chart {...props} />;
    case 'SectionHeading':
      return <SectionHeading {...props.props} />;
    case 'PieChart':
      return <PieChart {...props} />;
    case 'table':
      return <Table {...props} />;
    case 'quickReply':
      return <QuickReply {...props} />;
    case 'video':
      return <VideoMessage {...props}/>;
    case 'image':
    case 'picture':
      return <ImageMessage {...props}/>;
    case 'DescriptionList':
      return <DescriptionList {...props}/>
    case 'CompareStats':
      return <CompareStats {...props} />
    // case 'markdown':
    //   return (
    //     <Suspense fallback={null}>
    //       <Markdown {...props}/>
    //     </Suspense>
    //   );
    default:
      return null;
  }
}

export default React.memo(ShortMessage);