import React from 'react';
import { useChartDataParser } from 'utils/parser';
import cls from 'classnames';
import {
  BarChart, Bar, XAxis, YAxis, LineChart, Line,
  Tooltip, ResponsiveContainer, ComposedChart,
  AreaChart, Area, Legend
} from 'recharts';
import CompareStats from 'components/CompareStats';

const colors = [
  ['#5A67D8', '#8da2fb', 2],
  ['#c27803', '#e3a008', 2]
  // ['#0694a2', '#16bdca', 2],
];

function MsgChart(props) {

  const {
    charts, className, title,
    data, compare, currency,
    ...rest
  } = useChartDataParser(props.data, props);

  const _charts = React.useMemo(() => {
    return charts.map((el, k) => {
      const { data, type, ...props } = el;
      switch (type) {
        case 'bar':
          return (
            <Bar
              key={k}
              radius={[5, 5, 5, 5]}
              fill={colors[k][0]}
              {...props}
            ></Bar>
          );
        case 'line':
          return (
            <Line
              key={k}
              type="monotone"
              stroke={colors[k][0]}
              strokeWidth={colors[k][2]}
              {...props}
            />
          )
        case 'area':
          return (
            <Area
              key={k}
              type="monotone"
              stroke={colors[k][0]}
              fill={colors[k][1]}
              strokeWidth={colors[k][2]}
              {...props}
            />
          )
        default:
          return null;
      }
    });
  }, [charts]);

  const ChartWrap = React.useMemo(() => {
    let C = ComposedChart;
    let type = null;
    const sameType = charts.every((el, k) => {
      if (k === 0 || el.type === type) {
        type = el.type;
        return true;
      }
      return false;
    });
    if (sameType) {
      switch (type) {
        case 'bar':
          return BarChart;
        case 'line':
          return LineChart;
        case 'area':
          return AreaChart;
        default:
          break;
      }
    }
    return C;
  }, [charts]);

  return (
    <div className={cls("w-full h-full flex flex-col", className)}>
      <CompareStats 
        title={title}
        to={charts[0]?.total} 
        from={compare ? charts[1]?.total : null}
        currency={currency}
        className="mb-1"
        center
      />
      <ResponsiveContainer className="min-h-0 flex-1 flex flex-col">
        <ChartWrap data={data}>
          <XAxis
            dataKey="name0"
            axisLine={false}
            tickLine={false}
            padding={{
              left: 10,
              right: 10
            }}
            {...rest.xaxis}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            padding={{
              bottom: 10,
              top: 10
            }}
            {...rest.yaxis}
          />
          <Tooltip
            cursor={{ fill: '#f8fafc', radius: [5, 5, 5, 5] }}
            {...rest.tooltip}
          />
          {_charts}
          {charts.length > 1 && <Legend {...rest.legend} />}
        </ChartWrap>
      </ResponsiveContainer>
    </div>
  )
}
const LatestMsgChart = React.memo(MsgChart);

export default React.memo(props => {
  props = React.useMemo(() => {
    if (props.parseData) {
      const { parseData, ...rest } = props;
      return {
        ...rest,
        data: parseData.data || props.data,
        charts: parseData.charts || props.charts
      }
    }
    return props;
  }, [props]);
  return <LatestMsgChart {...props} />
});