import React from 'react'
import { useSpring, animated } from 'react-spring';
import cls from 'classnames';
import './Recording.css'

const Border = React.memo(() => {
  const style = useSpring({
    from: { transform: "scale(1)" },
    to: async next => {
      while (1) {
        await next({ 
          transform: "scale(1.3)"
        });
        await next({ 
          transform: "scale(1)"
        });
      }
    },
    config: { duration: 900 }
  });

  return (
    <animated.div
      className="will-size rounded-full w-full h-full border border-red-600 absolute flex"
      style={style}
    />
  )
})

const Circle = React.memo(() => {
  const style = useSpring({
    from: { opacity: 1 },
    to: async next => {
      while (1) {
        await next({ opacity: 0.4 });
        await next({ opacity: 1 });
      }
    },
    config: { duration: 900 }
  });

  return (
    <animated.div
      className="will-opacity rounded-full w-full h-full bg-red-600 absolute flex"
      style={style}
    />
  )
})

function Recording({
  className, ...props
}) {
  return (
    <div
      className={cls("w-5 h-5 absolute flex items-center justify-center", className)}
      {...props}
    >
      <Circle/>
      <Border/>
    </div>
  )
}

export default React.memo(Recording);