import React from 'react';
import Button from 'components/Button';
import { sendMessage } from "actions/input";
import { connect } from 'react-redux';

function QuickMessage({
  value, sendMessage, 
  onClick, ...props
}) {

  const _onClick = React.useCallback(e => {
    sendMessage(value);
    onClick && onClick(e);
  }, [sendMessage, value, onClick]);

  return (
    <Button
      secondary
      onClick={_onClick}
      {...props}
    >
      { value }
    </Button>
  )
}

export default connect(null, {
  sendMessage
})(QuickMessage);