import React from 'react';
import moment from 'moment';

function MessageTime({
  value
}) {
  return (
    <time
      className="ml-2 text-xs text-gray-400"
      dateTime={moment(value).format("YYYY-MM-DD HH:mm")}
    >
      {moment(value).format("hh:mm a")}
    </time>
  )
}

export default React.memo(MessageTime);