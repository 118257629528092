import React from 'react';
import cls from 'classnames';

function FormTitle({
  title, 
  description, 
  size = '3xl',
  className
}) {
  return (
    <div className={cls(`max-w-${size} mx-auto`, className)}>
      {
        title && <h3 className="text-lg leading-6 font-medium text-gray-900">
          { title }
        </h3>
      }
      {
        description && <p className="mt-1 text-sm leading-5 text-gray-500">
          { description }
        </p>
      }  
    </div>      
  )
}

export default React.memo(FormTitle);