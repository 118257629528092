import React from 'react';
import Select from "../FormElements/Select";
import { useFormContext } from "react-hook-form";

function FormSelect({
  name, value, validationOptions,
  onChange, ...props
}) {
  const { register, unregister, setValue, watch, errors } = useFormContext();
  const val = watch(name);
  const error = errors[name] ? errors[name].message : null;
  
  const _onChange = React.useCallback(value => {
    if (value.target) {
      value = value.target.value;
    }
    setValue(name, value, { shouldValidate: true });
    onChange && onChange(value);
  }, [setValue, name, onChange]);

  React.useEffect(() => {
    register({ name }, validationOptions);
    if (value) {
      setValue(name, value, { shouldValidate: true });
    }
    return () => unregister(name);
  }, [name, register, unregister, validationOptions, value, setValue]);

  return <Select
    onChange={_onChange}
    value={val}
    name={name}
    error={error}
    {...props}
  />;
}

export default React.memo(FormSelect);