import Auth from '@aws-amplify/auth';
import { apiName } from 'store/constants';

export default {
  Auth: {
    identityPoolId: 'eu-central-1:84890fb8-39ac-4922-b48c-46f201cde5e0',
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_hq4a3a4YB',
    userPoolWebClientId: '16ualc6aaum06d2ncbs4r50p13',
    mandatorySignIn: true
  },
  API: {
    endpoints: [{
      name: apiName,
      endpoint: (() => {
        if (process.env.NODE_ENV === 'production') {
          return "https://api-v2.hala.ai";
        }
        return "http://localhost:9000";
      })(),
      "custom_header": async () => {
        return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken() };
      }
    }]
  },
  Storage: {
    AWSS3: {
      bucket: 'hala-user-files',
      region: 'eu-central-1'
    }
  }
};