import React from 'react';

function HeaderGroup({ header }) {
  return (
    <tr {...header.getHeaderGroupProps()}>
      {header.headers.map(column => (
        <th {...column.getHeaderProps()}>
          {column.render('Header')}
        </th>
      ))}
    </tr>
  )
}

export default HeaderGroup;