import React from 'react';
import CompanyLogo from 'components/CompanyLogo';
function MessageLogo({
  icon, image
}) {
  return (
    <div className="flex-shrink-0">
      {
        image 
        ? <img 
            className="h-10 w-10 rounded-full" 
            src={image} 
            alt="" 
          />
        : (icon === 'hala' 
          ? <CompanyLogo/>
          : <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
              <span className="font-medium leading-none text-white">{icon}</span>
            </span>
        )
      }   
    </div>
  )
}

export default React.memo(MessageLogo);