import React from 'react';
import {
  useTable
} from 'react-table';
import Row from './Row';
import HeaderGroup from './HeaderGroup';
import useTableOptions from './hooks/useTableOptions';
import AddRowButton from './hooks/useRowEdit/AddRowButton';
import cls from 'classnames';

export const TableContext = React.createContext();

function Table(props) {
  const { plugins, options } = useTableOptions(props);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    context,
    RowWrap,
    tbodyRef
  } = useTable(options, ...plugins);

  return (
    <TableContext.Provider value={context}>
      <div className={cls("align-middle min-w-full", {
        "overflow-x-auto overflow-hidden": context.tableOverflow
      })}>
        <table {...getTableProps({
          className: "min-w-full"
        })}>
          <thead>
            {headerGroups.map((header, key) => (
              <HeaderGroup key={key} header={header} />
            ))}
          </thead>
          <tbody ref={tbodyRef} {...getTableBodyProps()}>
            {rows.map((row, key) => {
              prepareRow(row);
              return (
                <RowWrap key={key} row={row}>
                  <Row row={row} />
                </RowWrap>
              )
            })}
            <AddRowButton/>
          </tbody>
        </table>
      </div>
    </TableContext.Provider>
  );
}

export default React.memo(Table);