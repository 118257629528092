import { useMemo } from 'react';
import { dataParser } from 'utils/parser';
import {
  useExpanded, useRowSelect
} from 'react-table';
import useStyle from './useStyle';
import Cell from '../DefaultCell';
import ExpandedCell from '../ExpandedCell';
import EditableCell from './useEditable/EditableCell';
import useExpandedColumn from './useExpandedColumn';
import useRowSelectExtend from './useRowSelectExtend';
import useRowDetail from './useRowDetail';
import useRowEdit from './useRowEdit';
import useContext from './useContext';
import useEditable from './useEditable';
import RowWrap from '../RowWrap';
import EditableEmptyCell from './useEditable/EmptyCell';

const defaultColumn = {
  Cell,
  ExpandedCell,
  EditableCell,
  EditableEmptyCell
};

export default function(props) {
  const options = useMemo(() => {
    const columns = props.columns && props.columns.map(col => {
      let ext = {...col};
      if (ext.render) {
        ext.renderer = ext.render;
        delete ext.render;
      }
      return ext;
    });
    const parsed = dataParser(props.data || [], {
      ...props,
      columns: columns,
      type: 'table'
    });
    let rowWrap = RowWrap;
    const hasExpanded = parsed.data.some(el => el.subRows && el.subRows.length ? true : false);

    return Object.assign({
      defaultColumn, hasExpanded,
      initialState: {
        hiddenColumns: props.hiddenColumns || []
      },
      RowWrap: rowWrap
    }, props, {columns}, parsed);
  }, [props]);

  const {
    hasExpanded, rowSelection, editable,
    canAddRow ,canEditRow, canDeleteRow,
    hiddenColumns
  } = options;

  const plugins = useMemo(() => {
    const pls = [useStyle, useContext];
    if (hasExpanded) pls.push(useExpanded, useExpandedColumn);
    if (rowSelection) {
      pls.push(useRowSelect, useRowSelectExtend);
    }
    if (editable) {
      pls.push(useEditable);
    } else if (canAddRow || canEditRow || canDeleteRow) {
      pls.push(useRowEdit);
    } else if (hiddenColumns && hiddenColumns.length) {
      pls.push(useRowDetail);
    }
    return pls;
  }, [
    hasExpanded, rowSelection, hiddenColumns,
    editable,  canAddRow ,canEditRow, canDeleteRow,
  ]);

  return {
    options, plugins
  };
}