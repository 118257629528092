import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import { connect as conn } from 'react-redux';
import {
  connect,
  disconnect
} from 'actions/socket';
import ChatLayout from 'containers/ChatLayout';
import Header from 'containers/Header';
import { id } from 'components/Slideover';
const QuickBooks = React.lazy(() => import('pages/AddIntegration/QuickBooks'));

function App({
  connect, disconnect
}) {

  React.useEffect(() => {
    connect();
    return () => disconnect();
    // eslint-disable-next-line
  }, []);

  return (
    <Router>
      <div id={id} className="w-screen h-screen flex flex-col">
        <div className="absolute right-0 flex-shrink-0 space-x-4 flex">
          <Header/>
        </div>
        <Route exact path="/">
          <ChatLayout/>
        </Route>
        <Route exact path="/add-integration/quickbooks">
          <Suspense fallback={null}>
            <QuickBooks/>
          </Suspense>
        </Route>
      </div>
    </Router>
  );
}

const actions = {
  connect, disconnect
};

export default conn(null, actions)(App);