import ActionsCell from './ActionsCell';
import { useMountedLayoutEffect } from "react-table";
import { useMemo } from 'react';

const actions = {
  setEditRow: 'setEditRow',
  closeEditRow: 'closeEditRow',
  resetEditRow: 'resetEditRow'
};

function reducer(state, action) {
  switch (action.type) {
    case actions.setEditRow:
      return {
        ...state,
        editRowId: action.id
      };
    case actions.resetEditRow:
    case actions.init:
    case actions.closeEditRow:
      return {
        ...state,
        editRowId: null
      };
    default:
      return state;
  }
}

export const columnId = 'actionsColumn';

const actionsColumn = {
  id: columnId,
  Cell: ActionsCell,
  Header: 'Actions'
};

function useInstance(instance) {
  const {
    dispatch,
    rows,
    state: { editRowId }
  } = instance;

  useMountedLayoutEffect(() => {
    dispatch({ type: actions.resetEditRow });
  }, [dispatch]);

  rows.forEach(row => {
    row.isEdit = editRowId === row.id;
  });

  const extendInstance = useMemo(() => {
    return {
      setEditRow: id => dispatch({ type: actions.setEditRow, id }),
      closeEditRow: () => dispatch({ type: actions.closeEditRow })
    };
  }, [dispatch]);

  Object.assign(instance, extendInstance);
}

export default function (hooks) {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.visibleColumns.push(columns => [
    ...columns,
    actionsColumn
  ]);
}