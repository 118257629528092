import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, LabelList,
  Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { compile } from 'utils/hb';
import CustomizedLabel from "./CustomizedLabel";
import cls from 'classnames';

const defColors = [
  "#5A67D8", "#38A169", "#E53E3E", "#319795"
]

function parseVal(e) {
  let val = e || 0;
  if (typeof e === 'string') {
    val = parseFloat(e.replace(',', '.'));
  }
  return val;
}

function prepareData(data = [], {
  options, xaxis, bars = []
}) {
  let _data = data;
  _data = Array.isArray(_data) ? _data.slice() : [];
  const { groupBy, hideZero } = options || {};
  const xKey = xaxis && xaxis.dataKey ? xaxis.dataKey : 'name';
  let _bars = bars.slice();

  if (!_data.length) {
    _bars = _bars.map((bar, key) => {
      if (bar.data) {
        const newKey = `${bar.dataKey}${key}`;
        bar.data.forEach(d => {
          _data.push({
            ...d,
            [newKey]: d[bar.dataKey]
          })
        })
        delete bar.data;
        bar.dataKey = newKey;
      }
      return bar;
    });
  }

  if (groupBy) {
    _data = _data.reduce((res, el) => {
      const k = el[xKey];
      if (!k) return res;
      if (res[k]) {
        bars.forEach(bar => {
          res[k][bar.dataKey] += parseVal(el[bar.dataKey]);
        });
      } else {
        res[k] = {
          [xKey]: k
        }
        bars.forEach(bar => {
          res[k][bar.dataKey] = parseVal(el[bar.dataKey]);
        });
      }
      return res;
    }, []);
    _data = Object.values(_data);
  }

  if (hideZero) {
    _data = _data.filter(el => {
      return bars.some(b => {
        if (el[b.dataKey]) {
          return true;
        }
        return false;
      })
    })
  }

  return { _data, _bars };
}

// function useDataParser(data, options) {
//   if (options) {
//     console.log('!!!!!', data)
//     return [];
//   }
//   return data;
// }

function MsgBarChart({
  data, bars,
  xaxis = {}, yaxis = {},
  dataOptions, formatValue,
  showLabel, legend = true,
  className
}) {
  const formatter = React.useMemo(() => {
    if (formatValue) {
      const template = compile(formatValue);
      return val => template({val});
    }
    return undefined;
  }, [formatValue]);

  const { _data, _bars } = React.useMemo(() => {
    return prepareData(data, {
      options: dataOptions,
      xaxis, bars
    });
  }, [data, dataOptions, xaxis, bars]);

  return (
    <div className={cls("w-full h-full", className)}>
      <ResponsiveContainer>
        <BarChart
          data={_data}
        // layout="vertical"
        >
          <XAxis
            dataKey="name"
            // type="number"
            axisLine={false}
            tickLine={false}
            padding={{
              left: 10,
              right: 10
            }}
            {...xaxis}
          />
          <YAxis
            // type="category"
            // dataKey="name"
            axisLine={false}
            tickLine={false}
            padding={{
              bottom: 10,
              top: 10
            }}
            // width={100}
            tickFormatter={formatter}
            {...yaxis}
          />
          <Tooltip
            cursor={{ fill: '#f8fafc', radius: [5, 5, 5, 5] }}
            formatter={formatter}
          />
          {legend && <Legend />}
          {
            _bars.map((b, k) => (
              <Bar
                key={k}
                dataKey="pv"
                radius={[5, 5, 5, 5]}
                fill={defColors[k] || defColors[0]}
                {...b}
              >
                {
                  showLabel && <LabelList
                    dataKey={yaxis.dataKey}
                    content={<CustomizedLabel formatter={formatter}/>}
                  />
                }
              </Bar>
            ))
          }
        </BarChart>
      </ResponsiveContainer>
    </div>
  );

}

export default React.memo(MsgBarChart);