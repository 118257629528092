import React from 'react';
import cls from 'classnames';

function Panel({
  className, ...props
}) {
  return (
    <div 
      className={cls('bg-white overflow-hidden shadow rounded-lg', className)}
      {...props}
    />
  )
}

export default React.memo(Panel);