import React from 'react';
import AsyncSelect from 'react-select/async';
import { convertOptions } from './index';
import API from '@aws-amplify/api';
import { apiName } from 'store/constants';

function filter(options, inputValue) {
  let items = options.slice();
  if (inputValue === '-') {
    inputValue = '';
  }
  if (inputValue) {
    items = items.filter(i =>
      (i.label && i.label.toLowerCase().includes(inputValue.toLowerCase()))
      || (i.value && i.value.toLowerCase().includes(inputValue.toLowerCase()))
    );
  }
  if (items.length > 100) {
    items = items.slice(0, 100);
    items.push({
      value: 'Type to get more results...',
      label: 'Type to get more results...',
      isDisabled: true
    })
  }
  return items;
}

const cache = (() => {

  const data = {};

  function get(id) {
    if (!data[id]) return null;
    if (data[id].exp < new Date().getTime()) {
      return null;
    }
    return data[id].data;
  }

  function set(id, _data) {
    data[id] = {
      exp: new Date().getTime() + 5 * 60 * 1000,
      data: _data
    };
  }

  return {
    get, set
  }
})();


function useLoadOptions(props, format) {
  const { action, type, parameters = {} } = props;
  const [options, setOptions] = React.useState(null);
  const fetch = React.useCallback(async search => {
    if (!options) {
      const url = `/actions/${action}/exec?type=${type}`;
      const param = { body: parameters };
      const cacheId = `${url}:${JSON.stringify(param)}`;
      let data = cache.get(cacheId);
      if (!data || (Array.isArray(data) && !data.length)) {
        try {
          data = await API.post(apiName, url, param);
          cache.set(cacheId, data);
        } catch (err) {
          console.error(err);
        }
      }
      data = format(data);
      setOptions(data);
      return filter(data, search);
    }
    return filter(options, search);
  }, [options, action, format, type, parameters]);
  return fetch;
}

function RSelectRemote({
  optionsFormat, remote,
  ...props
}) {
  const remoteType = remote?.type;
  const format = React.useCallback(options => {
    return convertOptions(options, optionsFormat, remoteType);
  }, [optionsFormat, remoteType]);
  const fetch = useLoadOptions(remote, format);

  return (
    <AsyncSelect
      menuPlacement="auto"
      {...props}
      remoteType={remoteType}
      cacheOptions
      defaultOptions={true}
      loadOptions={fetch}
    />
  );
}

export default RSelectRemote;