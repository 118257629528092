import React from 'react';
import RowDropdown from 'components/Dropdown/BtnIcon';
import RowForm from './RowForm';

function ActionsCell({
  onChange, setEditRow, 
  closeEditRow,
  row
}) {
  const {id, isEdit, original} = row;

  const onClose = React.useCallback(() => closeEditRow(), [closeEditRow]);
  const onSubmit = React.useCallback(data => {
    onChange({
      type: "edit",
      data, id
    });
  }, [onChange, id]);
  const items = React.useMemo(() => {
    return [{
      name: "Edit",
      icon: "s-pencil-alt",
      onClick: () => setEditRow(id)
    }, {
      name: "Duplicate",
      icon: "s-duplicate",
      onClick: () => onChange({
        type: 'duplicate',
        id
      })
    },{
      border: true,
      name: "Delete",
      icon: "s-trash",
      onClick: () => onChange({
        type: 'remove',
        id
      })
    }]
  }, [setEditRow, onChange, id]);

  return (
    <>
      <RowDropdown 
        items={items}
        icon="s-dots-vertical"
      />
      {
        isEdit && <RowForm 
          onClose={onClose}
          onSubmit={onSubmit}
          values={original}
        />
      }
    </>
  ) 
}

export default React.memo(ActionsCell)