import React from 'react';
import MessagesList from './MessagesList';
import MessageInputType from './MessageInputType';
import WellcomeSection from 'containers/WellcomeSection';
import { connect } from 'react-redux';

function ChatLayout({
  wellcome
}) {

  if (wellcome) {
    return <WellcomeSection/>;
  }

  return (
    <>
      <div className="h-0 flex-1 overflow-y-auto w-full">
        <div className="space-y-3 py-2 px-2 md:px-0">
          <MessagesList />
        </div>
      </div>
      <div className="flex-shrink-0 flex px-2 md:px-0">
        <MessageInputType />
      </div>
    </>
  )
}

export default connect(
  s => ({
    wellcome: s.messages.length ? false : true
  })
)(ChatLayout);