import React from 'react';
import cls from 'classnames';
import NotificationItem from './NotificationItem';

function Notifications({
  className,
  items,
  onItemClose,
  ...props
}) {
  return (
    <div 
      {...props}
      className={cls(
        "fixed flex-col inset-0 flex items-end justify-start px-4 py-6 pointer-events-none sm:p-6",
        className
      )}
    >
      {
        items.map(el => (
          <NotificationItem 
            key={el.id}
            id={el.id}
            onClose={onItemClose}
            {...el.data}
          />
        ))
      }
    </div>
  )
}

export default React.memo(Notifications);