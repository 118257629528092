import React, { useEffect, useCallback } from 'react';
import SizeContainer from 'components/SizeContainer';
import { useFormContext } from "react-hook-form";
import Table from 'components/Message/Type/Table';
import { compile } from 'utils/hb';

function FormTableItem({
  size, name, validationOptions,
  type, label, description,
  rowSelection, data = [],
  ...props
}) {
  const { register, unregister, watch, setValue, clearErrors } = useFormContext();
  const fields = rowSelection ? data : watch(name);

  useEffect(() => {
    register({ name }, validationOptions);
    return () => unregister(name);
  }, [name, register, unregister, validationOptions]);

  const { columns } = props;
  const preChange = React.useMemo(() => {
    const opts = columns.reduce((o, el) => {
      if (el?.fieldOptions?.onChange) {
        if (!o) o = {};
        const id = el.id || el.accessor;
        o[id] = Object.keys(el.fieldOptions.onChange).map(key => ({
          key,
          val: compile(el.fieldOptions.onChange[key])
        }))
      }
      return o;
    }, null);
    if (!opts) return null;
    return (prev, next) => {
      let n = {
        ...prev,
        ...next
      };
      try {
        Object.keys(next).forEach(k => {
          opts[k] && opts[k].forEach(el => {
            n[el.key] = el.val(n);
          })
        })
      } catch(err){
        console.log(err);
      }
      return n;
    };
  }, [columns]);

  const onChange = useCallback(e => {
    let { type, id, data } = e || {};
    id = parseInt(id);
    switch (type) {
      case 'select':
        setValue(name, data);
        break;
      case 'edit':
        setValue(name, [...fields.map((el, key) => {
          if (key === id) {
            if (preChange) {
              return preChange(el, data);
            }
            return {
              ...el,
              ...data
            };
          }
          return el;
        })]);
        break;
      case 'remove':
        setValue(name, [...fields.filter((el, key) => key !== id)]);
        break;
      case 'duplicate': {
        const item = {...fields[id]}
        fields.splice(id, 0, item);
        setValue(name, [...fields]);
        break;
      }
      case 'add': {
        data = Array.isArray(data) ? data : [data]
        setValue(name, [...fields || [], ...data]);
        break;
      }
      default:
        break;
    }
    clearErrors(name);
  }, [fields, setValue, name, clearErrors, preChange]);

  return (
    <SizeContainer size={size}>
      <Table
        data={fields}
        rowSelection={rowSelection}
        onChange={onChange}
        {...props}
      />
    </SizeContainer>
  );
}

export default React.memo(FormTableItem);