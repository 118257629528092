import React, { memo } from 'react';
import Input from '../FormElements/Input';
import { Controller, useFormContext } from "react-hook-form";
import useFormFieldOptions from 'hooks/useFormFieldOptions';

function FormInput({
  name, value, validationOptions, 
  fieldOptions,
  onBlur: _onBlur,
  ...props
}){
  const { control, errors } = useFormContext();
  const error = errors && errors[name] && errors[name].message;
  value = value || '';
  useFormFieldOptions(name, fieldOptions);

  return (
    <Controller
      render={({ onBlur, ...rest }) => (
        <Input 
          {...props} 
          {...rest}
          onBlur={e => {onBlur(e); _onBlur && _onBlur(e)}}
          error={error} 
        />
      )}
      name={name}
      defaultValue={value}
      control={control}
      rules={validationOptions}
    />
  );
}

export default memo(FormInput);