import React from 'react';
import NativeSelect from './NativeSelect';
import RSelect from './RSelect';

function Select({
    native,
    ...props
}) {
  if (native) {
    return <NativeSelect {...props} />;
  }
  return <RSelect {...props} />;
}

export default React.memo(Select);