import React from 'react';
import Icon from 'components/Icon';
import Dropdown from './index';

function BtnIconDropdown({icon, ...props}) {
  return (
    <Dropdown {...props}>
      <button
        type="button"
        className="w-8 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition ease-in-out duration-150"
      >
        <Icon className="w-5 h-5" name={icon} />
      </button>
    </Dropdown>
  )
}

export default React.memo(BtnIconDropdown);