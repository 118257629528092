import React from 'react';
import Button from 'components/Button/QuickMessage';
import { removeMessage } from "actions/input";
import { connect } from 'react-redux';
import cls from 'classnames';

function QuickReply({
  value, id, removeMessage, size
}) {

  const isCenter = size && size !== 'sm'
  const _value = React.useMemo(() => {
    return value.reduce((v, el) => {
      if (!v.includes(el)) {
        v.push(el);
      }
      return v;
    }, []);
  }, [value]);

  const onClick = React.useCallback(() => removeMessage(id), [removeMessage, id]);

  return (
    <div className={cls({
      "flex flex-wrap justify-center": isCenter
    })}>
      {
        _value.map((val, key) => (
          <Button
            value={val}
            className="m-1"
            key={key}
            onClick={onClick}
          />
        ))
      }
    </div>
  )
}

export default connect(null, {
  removeMessage
})(QuickReply);