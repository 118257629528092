import React from "react";

function CustomizedLabel(props) {
  const {
    x, y, width, height, value,
    formatter
  } = props;
  
  const isSmall = height < 20;
  const _value = React.useMemo(() => {
    return formatter ? formatter(value) : value
  }, [formatter, value]);

  return (
    <g>
      <text 
        className="font-medium"
        fill={isSmall ? "#161e2e" : "white"}
        x={x + width / 2} 
        y={isSmall ? y - 10 : (y + height / 2)} 
        textAnchor="middle" 
        dominantBaseline="middle"
      >
        {_value}
      </text>
    </g>
  );
};

export default React.memo(CustomizedLabel);