import React from 'react';
import LongText from 'components/FormElements/LongText';
import { connect } from 'react-redux';
import { sendMessage } from 'actions/input';
import useAudioInput from 'hooks/useAudioInput'
import Recording from '../WellcomeSection/Recording';
import Icon from 'components/Icon';
import cls from 'classnames'

const autoSize = { minRows: 1, maxRows: 4 };

function MessageInput({
  sendMessage
}) {
  const {
    isRecording, isLoading, btnProps,
    isProcessing, isPreparing, placeholder
  } = useAudioInput(sendMessage);
  const [value, setValue] = React.useState('');

  const onChange = React.useCallback(e => setValue(e.target.value), []);
  const onPressEnter = React.useCallback(e => {
    e && e.preventDefault();
    setValue(v => {
      sendMessage(v);
      return '';
    })
  }, [sendMessage]);

  const canuseMic = React.useMemo(() => value ? false : true, [value]);

  return (
    <div className="flex w-full">
      {
        isLoading ? (
          <div className="relative flex items-center justify-center z-10">
            { isPreparing || isProcessing ? <Icon className="left-3 w-5 h-5 absolute text-red-600" name="oval" /> : null}
            { isRecording && <Recording className="left-3" />}
          </div>
        ) : null
      }
      <LongText
        wrapClassNames="w-full"
        classNames={cls("resize-none", {
          "pl-10": isLoading
        })}
        disabled={isLoading}
        autoSize={autoSize}
        textSm={false}
        autoFocus={true}
        placeholder={placeholder || 'Type your message...'}
        icon={
          <div {...(canuseMic ? btnProps : {onClick: onPressEnter})}>
            <Icon 
              className="h-6 w-6 cursor-pointer text-gray-400 hover:text-gray-600"
              name={canuseMic ? "o-microphone" : "o-check"} 
            />
          </div>
        }
        value={value}
        onChange={onChange}
        onPressEnter={onPressEnter}
      />
    </div>
  );
}

export default connect(
  null,
  {
    sendMessage
  }
)(MessageInput);