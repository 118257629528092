import React from 'react';
import FormSchema from 'components/Form/FormSchema';
import { inputSection } from 'store/constants';
import Portal from 'components/Portal';
import Button from 'components/Button';
import { connect } from 'react-redux';
import { submitForm, closeForm } from 'actions/input';

function MessageForm({
  formData, id,
  submitForm, closeForm
}) {
  const ref = React.useRef();
  const triggerSubmit = React.useCallback(() => {
    ref.current.submit();
  }, []);
  const onCancel = React.useCallback(() => closeForm(id), [id, closeForm]);
  const onSubmit = React.useCallback(data => submitForm(id, data), [id, submitForm]);

  return (
    <>
      <FormSchema
        onSubmit={onSubmit}
        ref={ref}
        items={formData}
      />
      <Portal name={inputSection}>
        <div className="border-t border-gray-200 pt-5">
          <div className="flex justify-end">
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              className="ml-3"
              type="submit"
              onClick={triggerSubmit}
            >Submit</Button>
          </div>
        </div>
      </Portal>
    </>
  )
}

export default connect(
  null,
  {
    submitForm, closeForm
  }
)(MessageForm);