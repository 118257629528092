import Control, { controlStyles } from './Control';
import Menu, { menuStyles } from './Menu';
import MenuList, { menuListStyles } from './MenuList';
import Option, { optionStyles } from './Option';
import MultiValue, { multiValueStyles } from './MultiValue';
import MultiValueLabel, { multiValueLabelStyles } from './MultiValueLabel';
import MultiValueRemove, { multiValueRemoveStyles } from './MultiValueRemove';
import ValueContainer, { valueContainerStyles } from './ValueContainer';
import SingleValue, { singleValueStyles } from './SingleValue';

const comps = { 
    Control, 
    Menu, 
    MenuList, 
    Option,
    MultiValueLabel, MultiValue, MultiValueRemove,
    ValueContainer,
    SingleValue
};

export const inpurStyles = {
    paddingRight: "2.5rem",
    paddingBottom: "0.5rem",
    paddingLeft: "0.75rem",
    paddingTop: "0.5rem",
    margin: "0"
};

export const styles = {
    input: base => ({
        ...base,
        ...inpurStyles,
        fontFamily: "inherit",
        fontSize: "inherit",
        color: "inherit",
        '& input + div': {
            fontFamily: 'inherit !important',
        }
    }),
    valueContainer: valueContainerStyles,
    placeholder: base => ({
        ...base,
        ...inpurStyles,
        color: '#a0aec0'
    }),
    singleValue: singleValueStyles,
    multiValue: multiValueStyles,
    multiValueLabel: multiValueLabelStyles,
    multiValueRemove: multiValueRemoveStyles,
    control: controlStyles,
    menu: menuStyles,
    menuPortal: base => {
        base.zIndex = 30;
        return base;
    },
    menuList: menuListStyles,
    option: optionStyles
};

export default {
    components: comps,
    styles: styles
};