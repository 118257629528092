import React from 'react';
import useScrollIntoView from 'hooks/useScrollIntoView';
import SizeContainer from 'components/SizeContainer';
import cls from 'classnames'

function MessageContainer({
  scrollIntoView = false,
  size, type, className,
  children
}) {
  const ref = useScrollIntoView(scrollIntoView);

  const _size = React.useMemo(() => {
    let cl = 'sm';
    switch (type) {
      case 'form':
      case 'form.v2':
      case 'grid':
        cl = 'full';
        break;
      case 'table':
        cl = 'xl';
        break;
      case 'BarChart':
      case 'PieChart':
      case 'chart':
        cl = 'lg';
        break;
      default:
        break;
    }
    return size || cl;
  }, [type, size]);

  const _cls = React.useMemo(() => {
    let _cls = '';
    switch (type) {
      case 'BarChart':
      case 'PieChart':
      case 'chart':
        _cls = 'h-screen-3/4';
        break;
      default:
        break;
    }
    return cls(className, _cls);
  }, [type, className])

  return React.useMemo(() => (
    <SizeContainer className={_cls} size={_size} ref={ref}>
      {children}
    </SizeContainer>
  ), [children, ref, _size, _cls]);
}
// ml-13
export default React.memo(MessageContainer);