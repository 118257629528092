import produce from "immer";
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import {
  CLIENT_MESSAGE, SERVER_MESSAGE,
  SIGNED_OUT, MESSAGE_LOADRER,
  SUBMIT_FORM, CLOSE_FORM, REMOVE_MESSAGE,
  CLIENT_IMAGE_MESSAGE, CLIENT_IMAGE_MESSAGE_ERROR,
  REFRESH
} from '../constants';

function createItems(payload) {
  const items = [];
  const def = {
    isHala: payload.isHala,
    date: payload.date,
    withLogo: false
  }
  payload.data.forEach(el => {
    switch(el.type) {
      case 'text':
        const {quickReply, ...props} = el;
        items.push({
          ...def,
          id: uuid(),
          ...props
        });
        quickReply && items.push({
          ...def,
          id: uuid(),
          type: 'quickReply',
          value: quickReply
        });
        break;
      default: {
        items.push({
          ...def,
          id: uuid(),
          ...el
        });
        break;
      }
    }
  });
  return items;
}

export default produce((draft, { type, payload }) => {
  switch (type) {
    case SERVER_MESSAGE: {
      let items = createItems(payload);
      let last = null;
      let withLogo = true;
      if (draft.length) {
        last = draft[draft.length - 1];
        withLogo = last.isHala !== payload.isHala;
      }
      items[0].withLogo = withLogo;
      if ((withLogo || (
        last && last.type === 'loader' && last.withLogo
      )) && !['text', 'loader'].includes(items[0].type)) {
        items.unshift({
          isHala: true,
          date: items[0].date,
          withLogo: items[0].withLogo,
          id: uuid(),
          type: 'text',
          value: ''
        });
        items[1].withLogo = false;
      }
      if (last && last.type === 'loader') {
        draft[draft.length - 1] = {
            ...last,
            ...items.shift(),
            withLogo: last.withLogo
        };
      }
      items.forEach(el => draft.push(el));
      break;
    }
    case MESSAGE_LOADRER: {
      const last = draft[draft.length - 1];
      if (payload) {
        if (last.type !== 'loader') {
          draft.push({
            id: uuid(),
            isHala: true,
            date: '',
            withLogo: !last.isHala,
            type: 'loader'
          });
        }
      } else {
        if (last.type === 'loader') {
          draft.splice(-1, 1);
        }
      }
      break;
    }
    case CLIENT_MESSAGE: {
      draft.push({
        id: uuid(),
        isHala: false,
        date: moment().format(),
        type: 'text',
        withLogo: true,
        value: payload
      });
      draft.push({
        id: uuid(),
        isHala: true,
        date: '',
        withLogo: true,
        type: 'loader'
      });
      break;
    }
    case CLIENT_IMAGE_MESSAGE: {
      const key = draft.findIndex(({type}) => type === 'filePicker');
      if (key !== -1) draft.splice(key, 1);
      draft.push({
        id: uuid(),
        isHala: false,
        date: moment().format(),
        type: 'text',
        withLogo: true,
        value: ''
      },{
        id: uuid(),
        isHala: false,
        date: moment().format(),
        // type: 'image',
        type: 'text',
        withLogo: false,
        value: 'File was uploaded successfully'
        // url: payload
      }, {
        id: uuid(),
        isHala: true,
        date: '',
        withLogo: true,
        type: 'loader'
      });
      break;
    }
    case CLIENT_IMAGE_MESSAGE_ERROR: {
      draft.push({
        id: uuid(),
        isHala: true,
        date: moment().format(),
        withLogo: true,
        type: 'text',
        value: payload
      });
      break;
    }
    case SUBMIT_FORM:
    case CLOSE_FORM: {
      let key = draft.length - 1;
      if (draft[key].type === 'devData') {
        key = draft.length - 2;
      }
      draft.splice(key, 1);
      break;
    }
    case REMOVE_MESSAGE: {
      const key = draft.findIndex(({id}) => id === payload);
      if (key !== -1) draft.splice(key, 1);
      break;
    }
    case SIGNED_OUT:
    case REFRESH:
      return [];
    default:
      break;
  }
}, []);