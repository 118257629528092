import React from 'react';
import './index.css';
import cls from 'classnames';

const MessageLoader = ({
  className
}) => (
  <div className={cls("spinner", className)}>
    <div className="bounce1 bg-indigo-600"></div>
    <div className="bounce2 bg-indigo-600"></div>
    <div className="bounce3 bg-indigo-600"></div>
  </div>
);

export default React.memo(MessageLoader);