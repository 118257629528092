import { useMemo, useEffect, useState, useCallback } from 'react';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc'

let stream = null;
let recorder = null;
let progress = 0;

export async function startRecording() {
  if (stream && recorder) {
    throw new Error('Recording in progress');
  }
  progress = 1;
  return await navigator.mediaDevices.getUserMedia({
    audio: true,
    video: false 
  }).then(st => {
    stream = st;
    if (progress === 1) {
      recorder = new RecordRTC(stream, {
        type: 'audio',
        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: 1
      });
      recorder.startRecording();
      return recorder;
    } else {
      stopRecording();
      return Promise.reject(new Error('stopped'));
    }
  });
}

export async function stopRecording() {
  progress = 0;
  recorder && await recorder.stopRecording();
  if (stream) {
    stream.stop();
    stream = null;
  }
  return recorder;
}

export async function getData() {
  return new Promise(async (resolve, reject) => {
    if (!recorder) {
      return resolve(null);
    }
    recorder.getDataURL(data => {
      const {size, type} = recorder.getBlob() || {};
      resolve({
        size, type,
        data: data
        .replace(/^data:audio\/wav;base64,/, "")
        .replace(/^data:audio\/webm;codecs=opus;base64,/, "")
      });
    });
  })
}

export async function destroyRecorder() {
  progress = 0;
  if (stream) await stopRecording();
  if (recorder) {
    recorder.destroy();
    recorder = null;
  }
  return;
}

export function useAudioRecording() {
  const [id, setId] = useState(null);
  const [state, setState] = useState({
    data: '',
    isProcessing: false,
    isRecording: false,
    isPreparing: false,
    isLoading : false
  });

  const isLoading = state.isLoading;
  const isPreparing = state.isPreparing;
  const stop = useCallback(async () => {
    if (id === null && isLoading && !isPreparing) {
      return;
    }
    setId(id => {
      if (id !== null) clearTimeout(id);
      return null;
    });
    await stopRecording();
    setState(st => ({
      ...st,
      isRecording: false,
      isProcessing: true
    }))
    const data = await getData();
    await destroyRecorder();
    setState({
      isRecording: false,
      isLoading: false,
      isProcessing: false,
      isPreparing: false,
      data
    })
  }, [isLoading, id, isPreparing]);

  const start = useCallback(async () => {
    if (id || isLoading) {
      return;
    }
    setState({
      data: '',
      isLoading: true,
      isPreparing: true,
      isRecording: false,
      isProcessing: false
    });
    try {
      await startRecording();
      setState(st => ({
        ...st,
        isPreparing: false,
        isRecording: true
      }));
      setId(setTimeout(stop, 15000));
    } catch(err) {}
  }, [stop, id, isLoading]);

  useEffect(() => () => destroyRecorder(), [])

  return useMemo(() => ({
    ...state, start, stop,
    toggle: state.isLoading ? stop : start
  }), [state, stop, start]);
}