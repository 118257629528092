import React from 'react'
import mediumZoom from 'medium-zoom'

function ImageZoom({ 
  zoomOptions = {}, alt,
  ...props
}) {
  const zoom = React.useRef(mediumZoom(zoomOptions))

  const ref = React.useCallback(image => {
    zoom.current.attach(image)
  }, [zoom]);

  return (
    <img 
      ref={ref} 
      alt={alt}
      {...props}
    />
  )
}

export default React.memo(ImageZoom);