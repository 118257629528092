import React from 'react';
import ReactDOM from 'react-dom';

function Portal({
  name, 
  children
}) { 
  const el = React.useMemo(() => document.createElement('div'), []);

  React.useEffect(() => {
    const root = document.getElementById(name);
    if (root) {
      root.appendChild(el);
      return () => root.removeChild(el);
    }
  }, [name, el]);

  return ReactDOM.createPortal(children, el);
}

export default React.memo(Portal);