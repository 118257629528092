import produce from "immer";
import {
  SIGNED_IN,
  SIGNED_OUT,
  SIGN_IN,
  SET_USER_INFO,
  VERIFY_CONTACT,
  REQUIRE_NEW_PASSWORD,
  AUTH_LOADING,
  AUTH_ERROR
} from '../constants';
import { firstSymbol } from "utils";

const initialState = {
  authState: '',
  user: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    icon: '',
    roles: [],
    sub: ''
  },
  loading: false
};

export default produce((draft, { type, authData }) => {
  switch (type) {
    case VERIFY_CONTACT:
    case REQUIRE_NEW_PASSWORD:
    case SIGN_IN:
      draft.authState = type;
      draft.loading = false;
      break;
    case AUTH_LOADING:
      draft.loading = authData;
      break;
    case AUTH_ERROR:
      draft.loading = false;
      break;
    case SIGNED_IN:
      draft.authState = type;
      draft.user.sub = authData.username;
      draft.loading = false;
      break;
    case SIGNED_OUT:
      return initialState;
    case SET_USER_INFO:
      draft.user.icon = `${firstSymbol(authData.firstName)}${firstSymbol(authData.lastName)}`;
      draft.user.firstName = authData.firstName;
      draft.user.lastName = authData.lastName;
      draft.user.email = authData.email;
      draft.user.roles = authData.roles;
      draft.user.sub = authData.sub;
      draft.user.id = authData.id;
      draft.loading = false;
      break;
    default:
      break;
  }
}, initialState);