import React from 'react';
import Transition from 'components/Transition';
import { opacity, fromRight } from 'components/Transition/Spring';
import Icon from 'components/Icon';
import Portal from 'components/Portal';
import { 
  easeExpOut,
  easeCubicIn
} from 'd3-ease';

export const SlideoverContext = React.createContext();
export const id = 'slideover';
const customOpacity = {
  config: (k, step) => {
    const c = {
      duration: 600,
      easing: easeExpOut
    }
    if (step === 'enter') {
      return {
        ...c,
        easing: easeCubicIn
      }
    }
    return c;
  },
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 }
};

function Slideover({
  children, footer, onClose,
  size = 'xl'
}) {
  const [show, setShow] = React.useState(false);
  const close = React.useCallback(() => {
    setShow(false);
    onClose && setTimeout(() => onClose(), 550);
  }, [onClose]);

  React.useEffect(() => setShow(true), []);

  return (
    <div className="fixed inset-0 overflow-hidden z-30">
      <div className="absolute inset-0 overflow-hidden">
        <Transition
          show={show}
          className="absolute inset-0 bg-gray-500 bg-opacity-75"
          onClick={close}
          options={opacity}
        />
        <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
          <Transition
            className={`relative w-screen max-w-${size}`}
            show={show}
            options={fromRight}
          >
            <Transition
              show={show}
              className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
              options={opacity}
            >
              <button onClick={close} aria-label="Close panel" className="text-gray-300 hover:text-white transition ease-in-out duration-150">
                <Icon className="h-6 w-6" name="s-x" />
              </button>
            </Transition>
            <div className="h-full flex flex-col space-y-6 bg-white shadow-xl overflow-y-scroll">
              <Transition
                className="h-full"
                show={show}
                options={customOpacity}
              >
                <SlideoverContext.Provider value={close}>
                  {footer ? (
                    <div className="h-full divide-y divide-gray-200 flex flex-col">
                      <div className="slideover-content min-h-0 flex-1 flex flex-col overflow-y-scroll overflow-hidden">
                        {children}
                      </div>
                      <div className="flex-shrink-0 flex">
                        {footer}
                      </div>
                    </div>
                  ) : children}
                </SlideoverContext.Provider>
              </Transition>
            </div>
          </Transition>
        </section>
      </div>
    </div>
  )
}

function SlideoverPortal(props) {
  return (
    <Portal name={id}>
      <Slideover {...props} />
    </Portal>
  )
}

export default React.memo(SlideoverPortal);