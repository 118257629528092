import React from "react";
import { connect } from 'react-redux';
import Button from 'components/Button/QuickMessage';

function WellcomeButtons({
  buttons
}) {
  return (
    <div className="flex flex-wrap flex-row items-center justify-center">
      {
        buttons.map((value, key) => (
          <Button className="m-1" key={key} value={value}/>
        ))
      }
    </div>
  );
}

export default connect(
  s => ({
    buttons: s.config.wellcomeButtons
  })
)(WellcomeButtons);