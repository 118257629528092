import React from 'react';
import SchemaEditor from 'components/SchemaEditor';
import Icon from 'components/Icon';

function DevData({ value }) {
  const [show, setShow] = React.useState(false);
  const toggleShow = React.useCallback(() => setShow(s => !s), []);

  const dev = React.useMemo(() => {
    const {
      intent = {},
      intents = {},
      entities = {},
      context = {}
    } = JSON.parse(value);

    const ints = Object.keys(intents).sort((a, b) => intents[b] - intents[a]).slice(0, 5);
    let _intents = {};
    ints.forEach(key => _intents[key] = intents[key]);

    return (
      <div>
        <p>Recognized intent with the highest confidence</p>
        <SchemaEditor
          value={intent}
          mode="view"
          label="intent"
        />
        <p>List of recognized entities</p>
        <SchemaEditor
          value={entities}
          mode="view"
          label="entities"
        />
        <p>Top five intents with confidence</p>
        <SchemaEditor
          value={_intents}
          mode="view"
          label="intents"
        />
        <p>List of the context variables</p>
        <SchemaEditor
          value={context}
          mode="view"
          label="context"
        />
      </div>
    );
  }, [value]);

  return (
    <>
      <div onClick={toggleShow} className="flex items-center cursor-pointer text-base leading-6 font-semibold text-indigo-600 hover:text-indigo-500 transition ease-in-out duration-150">
        <Icon className="w-5 h-5 inline" name="o-code" />
        <span className="ml-1">
          {
            show
              ? 'Hide message context'
              : 'Show message context'
          }
        </span>
      </div>
      {
        show
          ? dev
          : null
      }
    </>
  )
}

export default React.memo(DevData);