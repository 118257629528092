import React from 'react';
import { ResponsivePie } from '@nivo/pie'
import ChartTitle from 'components/CompareStats';
import { usePieChartDataParser } from 'utils/parser';
import {v1 as uuid} from 'uuid';

// const defmarginY = 30;
const defmarginX = 150;

const defProps = {
  innerRadius: 0.5,
  padAngle: 0.7,
  cornerRadius: 3,
  colors: { scheme: 'nivo' },
  borderWidth: 1,
  borderColor: { from: 'color', modifiers: [['darker', 0.2]] },
  radialLabelsSkipAngle: 10,
  radialLabelsTextXOffset: 6,
  // radialLabelsTextColor: "#333333",
  radialLabelsLinkOffset: 4,
  radialLabelsLinkDiagonalLength: 10,
  radialLabelsLinkHorizontalLength: 10,
  radialLabelsLinkStrokeWidth: 1,
  // radialLabelsLinkColor: { from: 'color' },
  slicesLabelsSkipAngle: 10,
  // slicesLabelsTextColor: "#333333",
  animate: true,
  motionStiffness: 90,
  motionDamping: 15,
  // theme:{
  //   labels: {
  //       container: {
  //           background: '#333',
  //       },
  //   },
  // },
  radialLabel: d => (
    <tspan className="text-sm">{d.label}</tspan>
  ),
  defs: [{
    id: 'dots',
    type: 'patternDots',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)',
    size: 4,
    padding: 1,
    stagger: true
  }, {
    id: 'lines',
    type: 'patternLines',
    background: 'inherit',
    color: 'rgba(255, 255, 255, 0.3)',
    rotation: -45,
    lineWidth: 6,
    spacing: 10
  }]
};

const legends = [{
  // anchor: 'bottom',
  // anchor: 'right',
  anchor: 'bottom-left',
  // direction: 'row',
  direction: 'column',
  translateX: -defmarginX,
  itemWidth: defmarginX,
  itemHeight: 20,
  itemTextColor: '#6b7280',
  symbolSize: 14,
  symbolShape: 'circle'
}];

// const marginLabels = { 
//   top: defmarginY, 
//   right: defmarginX, 
//   bottom: defmarginY, 
//   left: defmarginX 
// }

// const marginNoLabels = { 
//   // top: 15, 
//   // right: 15, 
//   // bottom: 15, 
//   // left: 15 
// }

function MsgPieChart(props) {

  const {
    total, title, currency, 
    legend = false,
    radialLabels = false, 
    ...rest
  } = usePieChartDataParser(props); 

  let otherProps = {}
  if (radialLabels || legends) {
    // console.log('!!!!!', rest.data)
    // otherProps.margin = marginLabels;
  }

  return (
    <div className="w-full h-full flex flex-col">
      <ChartTitle 
        title={title}
        to={total}
        currency={currency}
        center
      />
      <div className="min-h-0 flex-1 flex flex-col">
        <ResponsivePie
          id={uuid()}
          {...defProps}
          {...otherProps}
          {...(legend ? {legends} : {})}
          enableRadialLabels={radialLabels}
          {...rest}
        />
      </div>
    </div>
  )
}

export default React.memo(MsgPieChart);