import { 
  useRef, useCallback, useEffect
} from 'react';

export default function useScrollIntoView(trigger) {
  const ref = useRef(null);
  const scrollToBottom = useCallback(() => {
    if (ref.current) {
      const tId = setTimeout(() => {
        ref.current && ref.current.scrollIntoView({
          behavior: 'smooth'
        });
      }, 100);
      return () => clearTimeout(tId);
    }
  }, [ref]);
  useEffect(() => {
    trigger && scrollToBottom();
  }, [trigger, scrollToBottom]);
  return ref;
}