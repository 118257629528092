import React from 'react';
import Select from 'react-select';
import { convertOptions } from './index';

function RSelect({
  options = [], optionsFormat,
  ...props
}) {

  const _options = React.useMemo(() => {
    return convertOptions(options, optionsFormat);
  }, [options, optionsFormat]);

  return (
    <Select
      options={_options}
      menuPlacement="auto"
      {...props}
    />
  );
}

export default RSelect;