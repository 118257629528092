import React from 'react'
import { Provider } from 'react-redux'
import AppContainer from './AppContainer'
import configureStore from 'store';

const store = configureStore();

function Root() {
  return (
    <Provider store={store}>
      <AppContainer/>
    </Provider>
  )
}

export default React.memo(Root);
