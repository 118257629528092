import React from 'react';
import RSelect from './RSelect';
import RSelectRemote from './RSelectRemote';
import _get from 'lodash/get';
import defProps from './defaultOptions';

function convertByRemoteType(type, option, options) {
  switch(type) {
    case 'quickbooks': {
      const parentId = option?.ParentRef?.value;
      if (parentId) {
        const parent = options.find(({Id}) => Id === parentId);
        if (parent) {
          Object.keys(parent).forEach(key => {
            if (!option[key] && parent[key]) {
              option[key] = parent[key];
            }
          });
        }
      }
      break;
    }
    default:
      break;
  }

  return option;
}

export function convertOptions(options, optionsFormat, remoteType) {
  const { path, item } = optionsFormat || {};
  let opts = options;
  if (!opts) {
    return [];
  }
  if (path) {
    opts = _get(opts, path, []);
  }
  if (!Array.isArray(opts)) {
    return [];
  }
  return opts.map(o => {
    if (typeof o === 'string') {
      return {
        value: o,
        label: o
      }
    } else if (item) {
      if (remoteType) {
        o = convertByRemoteType(remoteType, o, opts);
      }
      return {
        ...o,
        value: _get(o, item.value, o.value),
        label: _get(o, item.label, o.label)
      }
    }
    return o;
  });
}

function RSelectWrap({
  styles, components, ...props
}) {
  const st = React.useMemo(() => {
    return {
      styles: Object.assign({}, defProps.styles, styles || {}),
      components: Object.assign({}, defProps.components, components || {})
    }
  }, [styles, components]);

  props.styles = st.styles;
  props.components = st.components;

  if (props.remote) {
    return <RSelectRemote {...props}/>;
  }
  return <RSelect {...props}/>;
}

export default RSelectWrap;