import React from 'react';
import {
  currencyTpl, currencyShortTpl
} from 'utils/hb';
import cls from 'classnames';
import Icon from 'components/Icon';

function CompareStats(props) {
  const {
    title, to, from, currency,
    description, center,
    shortNumbers = false, small = false,
    className
  } = props;

  const {
    compareTo, compareFrom, percent
  } = React.useMemo(() => {
    let increased = true;
    let percent = null;

    if (to && from) {
      const _to = parseFloat(to);
      const _from = parseFloat(from);
      percent = Math.abs(Math.round((_to - _from) / (_from / 100)));
      increased = _to - _from > 0
    }

    const tpl = shortNumbers ? currencyShortTpl : currencyTpl;
    const currencyClass1 = 'text-lg';
    const currencyClass2 = 'text-xs';

    if (percent) {
      percent = (
        <div className={cls("inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 md:mt-2 lg:mt-0", {
          'bg-green-100 text-green-800': increased,
          'bg-red-100 text-red-800': !increased
        })}
        >
          <Icon
            className={cls("-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4", {
              "text-green-500": increased,
              "text-red-500": !increased
            })}
            name={`s-arrow-${increased ? 'up' : 'down'}`}
          />
          <span className="sr-only">
            {increased ? 'Increased' : 'Decreased'} by
            </span>
          {percent}%
        </div>
      )
    }

    return {
      compareTo: tpl({ value: to, currency, currencyClass: currencyClass1 }),
      compareFrom: from ? tpl({ value: from, currency, currencyClass: currencyClass2 }) : null,
      percent
    }
  }, [to, from, currency, shortNumbers]);

  return (
    <div className={className}>
      <dl className={cls({
        "text-center": center
      })}>
        {title && <dt className="text-base leading-6 font-normal text-gray-900">
          {title}
          {description && <span 
            className="block text-sm leading-5 font-normal text-gray-500"
          >{description}</span>}
        </dt>}
        <dd 
          className={cls("mt-1 flex items-baseline md:block lg:flex lg:flex-wrap",{
            "justify-items-center justify-center": center,
            "justify-between": !center
          })}
        >
          <div className={cls("items-baseline text-2xl leading-8 font-semibold text-indigo-600", {
            "flex": !small
          })}>
            <span dangerouslySetInnerHTML={{ __html: compareTo }} />
            {compareFrom && <span className={cls("text-sm leading-5 font-medium text-gray-500", {
              "block": small,
              "ml-2": !small
            })}>
              from <span className={cls("mr-2")} dangerouslySetInnerHTML={{ __html: compareFrom }} />
              {small && percent}
            </span>}
          </div>
          {!small && percent}
        </dd>
      </dl>
    </div>
  )
}

export default React.memo(CompareStats);