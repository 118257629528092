import React from 'react';
import Logo from '../CompanyLogo';

function AuthLayout({
  title, description,
  children
}) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div>
          <Logo size="16"/>
          { 
            title && <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
              { title }
            </h2>
          }
          {
            description && <p className="mt-2 text-center text-sm leading-5 text-gray-600">
              { description }
            </p>
          }
        </div>
        { children }
      </div>
    </div>
  )
}

export default React.memo(AuthLayout);