import API from '@aws-amplify/api';
import {
  apiName,
  apis
} from '../constants';

export default ({dispatch}) => next => async action => {
  
  if (action.type !== apis.fetch) {
    return next(action);
  }
  
  const {
    path = '',
    // name = '',
    method = 'get',
    data = {},
    headers = {},
    qs = {},
  } = action.payload;

  next(action);
  // if (name) dispatch({type: name, payload: action.payload});

  return API[method](apiName, path, {
    queryStringParameters: qs,
    body: data,
    headers
  })
  .then(response => {
    return dispatch({type: apis.fetchSuccess, payload: action.payload, response});
    // if (name) dispatch({type: `${name}Success`, payload});
  }).catch(error => {
    dispatch({type: apis.fetchError, payload: action.payload, error});
    // if (name) dispatch({type: `${name}Error`, payload: action.payload, error});
    throw error;
  });
};