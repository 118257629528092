import React, { useCallback, useMemo } from 'react';
import Checkbox from '../FormElements/Checkbox';
import { useFormContext, Controller } from "react-hook-form";
import { useFormItemError } from 'hooks/useFormItemError';

function CheckboxInput ({
    name, value, validationOptions, ...props
}) {
    const { setValue, watch, control } = useFormContext();
    
    const defVal = useMemo(() => {
        if (typeof value === 'string') {
            return value === 'true';
        }
        return value ? true : false;
    }, [value]);
    const checked = watch(name, defVal); 
    const { ref, error } = useFormItemError(name);

    const _onChange = useCallback(e => {
        setValue(name, e.target.checked, { shouldValidate: true });
    }, [setValue, name]);

    return <Controller 
        render={({ onBlur, value, ...rest }) => (
            <Checkbox 
                ref={ref}
                {...rest}
                {...props}
                error={error}
                checked={value}
                name={name}
                onChange={_onChange}
                onBlur={onBlur}
            />
        )}
        name={name}
        control={control}
        defaultValue={checked}
    />;
};

export default CheckboxInput;