import React from 'react';
import { components } from 'react-select';

export const controlStyles = (base, state) => {
    return {
        ...base,
        backgroundColor: '#ffffff',
        borderColor: '#d2d6dc',
        padding: 0,
        backgroundImage: 'none',
        // boxShadow: "inherit",
        borderRadius: "0.375rem",
        borderWidth: "1px",
        fontSize: "1rem",
        "WebkitAppearance": "none",
        "MozAppearance": "none",
        "appearance": "none",
        "WebkitPrintColorAdjust": "exact",
        "colorAdjust": "exact",
        "fontWize": "1rem",
        "lineHeight": "1.5",
        '&:hover': {
            borderColor: 'inherit !important',
        },
        ...(state.isFocused ? {
            outline: "none",
            boxShadow: "0 0 0 3px rgba(164, 202, 254, 0.45)",
            borderColor: "#a4cafe"
        }: {})
    };
};

function Control(props) {
    return (
        <components.Control className="sm:text-sm sm:leading-5 shadow-sm" {...props} >
            { props.children }
        </components.Control>
    )
}

export default Control;