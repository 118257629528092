import React from 'react';
import Transition from 'components/Transition';
import Icon from 'components/Icon';
import cls from "classnames";

function NotificationItem({
  id, onClose, icon, iconCls,
  title, description, timeout = 7000
}) {
  const state = React.useRef();
  const [show, setShow] = React.useState(false);

  const destroy = React.useCallback(() => 
    onClose && onClose(id)
  , [onClose, id]);

  const close = React.useCallback(() => {
    state.current = 'destroy';
    setShow(false);
  }, []);

  React.useEffect(() => {
    setShow(true);
    if (timeout) {
      const tId = setTimeout(close, timeout);
      return () => clearTimeout(tId);
    }
  }, [close, timeout]);

  React.useEffect(() => {
    if (!show && state.current === 'destroy') {
      const tId = setTimeout(destroy, 100);
      return () => clearTimeout(tId);
    }
  }, [show, destroy]);

  return (
    <Transition
      show={show}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto mb-5">
        <div className="rounded-lg shadow-xs overflow-hidden">
          <div className="p-4">
            <div className="flex items-start">
              { icon && <div className="flex-shrink-0">
                <Icon className={cls("h-6 w-6", iconCls)} name={icon} /> 
              </div> }
              <div className="ml-3 w-0 flex-1 pt-0.5">
                { title && <p className="text-sm leading-5 font-medium text-gray-900">
                  {title}
                </p> }
                { description && <p 
                  className="mt-1 text-sm leading-5 text-gray-500"
                  dangerouslySetInnerHTML={{__html: description}}/>
                }
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button onClick={close} className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150">
                  <Icon className="h-5 w-5" name="s-x" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default React.memo(NotificationItem);