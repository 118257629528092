import React from 'react';
import { sendFileMessage } from 'actions/input';
import { connect } from 'react-redux';
import Button from 'components/Button';

function FilePicker({
  sendFileMessage, process
}) {
  const ref = React.useRef();

  const onChange = React.useCallback(e => {
    const file = e.target.files[0];
    sendFileMessage(file, { process });
  }, [process, sendFileMessage]);

  const onClick = React.useCallback(() => {
    ref.current && ref.current.click();
  }, [ref]);

  return (
    <div className="flex border-2 border-dashed border-gray-300 pt-3 pb-2">
      <Button className="mx-auto" onClick={onClick} primary>Please upload a file</Button>
      <input
        ref={ref}
        className="hidden"
        title="Select file"
        type="file"
        // accept="image/*"
        onChange={onChange}
      />
    </div>
  )
}

export default connect(null, {
  sendFileMessage
})(FilePicker);