import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import Icon from '../Icon';

function getSizeStyles(size) {
  switch (size) {
    case 'xs':
      return {
        i: '-ml-0.5 mr-2 h-4 w-4',
        b: 'px-2.5 py-1.5 text-xs leading-4 rounded'
      };
    case '2xs':
      return {
        i: '-ml-0.5 mr-2 h-4 w-4',
        b: 'px-3 py-2 text-sm leading-4 rounded-md'
      };
    case 'lg':
      return {
        i: '-ml-1 mr-3 h-5 w-5',
        b: 'px-4 py-2 text-base leading-6 rounded-md'
      };
    case '2lg':
      return {
        i: '-ml-1 mr-3 h-5 w-5',
        b: 'px-6 py-3 text-base leading-6 rounded-md'
      };
    default:
      return {
        i: '-ml-1 mr-2 h-5 w-5',
        b: 'px-4 py-2 text-sm leading-5 rounded-md'
      };
  }
}

function getStyles({
  primary, secondary, type, size,
  red
}) {
  const _type = (primary ? 'primary' : null)
    || (secondary ? 'secondary' : null)
    || (red ? 'red' : null)
    || (type === 'submit' ? 'primary' : null)

  let st = {
    cStyles: 'inline-flex rounded-md shadow-sm',
    bStyles: 'border-gray-300 text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50',
    iStyles: ''
  };

  const sh = 'inline-flex border font-medium relative group';

  switch (_type) {
    case 'primary':
      st.iStyles = 'text-indigo-500 group-hover:text-indigo-400 transition ease-in-out duration-150';
      st.bStyles = 'border-transparent text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150';
      break;
    case 'secondary':
      st.cStyles = 'inline-flex';
      st.bStyles = 'border-transparent text-indigo-700 bg-indigo-100 hover:bg-indigo-50 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-indigo-200';
      break;
    case 'red':
      st.bStyles = 'border-transparent bg-red-600 text-white hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red';
      break;
    default:
      break;
  }
  const { i, b } = getSizeStyles(size);
  st.bStyles += ` ${sh} ${b} w-full items-center justify-center transition ease-in-out duration-150`;
  st.iStyles += ` ${i}`;
  st.iLoading = i;
  return st;
}

function Button({
  children,
  type = "button",
  primary, secondary, red,
  className,
  icon,
  size,
  loading,
  disabled,
  iconLeft,
  label,
  link,
  onClick,
  ...props
}) {
  const { cStyles, bStyles, iStyles, iLoading } = useMemo(() =>
    getStyles({ primary, secondary, type, size, red })
    , [primary, secondary, type, size, red]);

  icon = React.useMemo(() => {
    let st = iStyles;
    let il = iLoading;
    if (iconLeft) {
      st = st.replace(/-ml-1/g, '');
      il = il.replace(/-ml-1/g, '');
    }
    const i = (icon || loading) && <Icon
      name={loading ? 'oval' : icon}
      className={loading ? il : st}
    />;
    if (iconLeft) {
      return (
        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
          {i}
        </span>
      );
    }
    return i;
  }, [iconLeft, icon, iLoading, iStyles, loading]);

  return (
    <span className={classnames(cStyles, className, {
      'opacity-50': disabled
    })}>
      {
        link
        ? (
          <a
            className={bStyles}
            href={link}
            onClick={loading || disabled ? e => e.preventDefault() : onClick}
            rel={props.target === '_blank' ? "noopener noreferrer" : null}
            {...props}
          >
            {icon}
            {children || label}
          </a>
        )
        : (
          <button
            className={bStyles}
            type={type}
            onClick={loading || disabled ? null : onClick}
            disabled={disabled}
          >
            {icon}
            {children || label}
          </button>
        )
      }
    </span>
  )
}

export default memo(Button);