import React from 'react';
import MessageLogo from "./MessageLogo";
import MessageTime from "./MessageTime";
import MessageLoader from 'components/BounceSpinner';

function MessageWithLogo({
  date,
  icon,
  username,
  type,
  value
}) {
  const msg = React.useMemo(() => {
    switch (type) {
      case 'text':
        return (
          <span dangerouslySetInnerHTML={{
            __html: value
          }} />
        );
      case 'loader':
        return <MessageLoader />;
      default:
        return '';
    }
  }, [type, value])

  return (
    <div className="flex-1 flex items-center overflow-hidden">
      <MessageLogo icon={icon} />
      <div className="ml-3">
        <span className="text-sm leading-5 font-medium text-gray-900">
          {username}
        </span>
        {date && <MessageTime value={date} />}
        <div className="flex text-base leading-6 text-gray-700">
          {msg}
        </div>
      </div>
    </div>
  )
}

export default React.memo(MessageWithLogo);