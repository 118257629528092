let config;
if (process.env.NODE_ENV === 'development') {
  config = require(`./index.${process.env.NODE_ENV}.js`).default
} else {
  config = {};
}

export default {
  socketUrl: 'https://api-v2.hala.ai',
  ...config
};