import React from 'react';
import Icon from 'components/Icon';
import Transition from 'components/Transition/Spring';
import {
  easeCubicOut
} from 'd3-ease';
import useOnClickOutside from 'hooks/useOnClickOutside';

const animation = {
  config: {
    duration: 200,
    easing: easeCubicOut
  },
  from: { transform: 'scale(0.95,0.95)', opacity: 0 },
  enter: { transform: 'scale(1,1)', opacity: 1 },
  leave: { transform: 'scale(0.95,0.95)', opacity: 0 }
};

function Item({
  name, icon, onClick
}) {
  return (
    <a
      onClick={e => {
        e.preventDefault();
        onClick && onClick();
      }}
      href="/" 
      className="group flex items-center px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" 
      role="menuitem"
    >
      {icon && <Icon 
        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 group-focus:text-gray-500" 
        name={icon} 
      />}
      {name}
    </a>
  );
}

const DropdownItem = React.memo(Item);

function Dropdown({
  children, items
}) {
  const [show, setShow] = React.useState(false);
  const toggle = React.useCallback(() => setShow(s => !s), []);
  const close = React.useCallback(() => setShow(false), []);
  const ref = useOnClickOutside(close);

  const _children = React.useMemo(() => {
    return React.cloneElement(children, {
      onClick: toggle,
      children: children.props.children
    });
  }, [toggle, children]);

  items = React.useMemo(() => {
    const n = items.length - 1;
    return items.reduce((res, props, key) => {
      if (props.border) {
        res.items.push(
          <div key={`g-${key - 1}`} className="py-1">{res.group}</div>,
          <div key={`b-${key}`} className="border-t border-gray-100"></div>
        );
        res.group = [];
      }
      res.group.push(<DropdownItem key={key} {...props} />);
      if (n === key) {
        res.items.push(
          <div key={`g-${key}`} className="py-1">{res.group}</div>
        );
        return res.items;
      }
      return res;
    }, { items: [], group: [] });
  }, [items]);

  return (
    <div
      ref={ref}
      className="relative flex justify-end items-center"
    >
      {_children}
      <Transition
        show={show}
        className="mx-3 z-20 origin-top-right absolute right-7 top-0 w-48 mt-1 rounded-md shadow-lg"
        options={animation}
      >
        <div onClick={close} className="rounded-md bg-white shadow-xs">
          {items}
        </div>
      </Transition>
    </div>
  )
}

export default React.memo(Dropdown);