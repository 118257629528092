import { 
  useCallback, useEffect, useState, useMemo
} from 'react'
import { useAudioRecording } from 'utils/audio'
import { socket } from 'actions/socket'
import useLongPress from 'hooks/useLongPress';

const audioInputResult = 'audioInputResult';

function emitAudioInput(data) {
  socket.emit('audioInput', data);
}

export default function useAudioInput(onAudioInput) {
  const {toggle, data, ...rest} = useAudioRecording();
  const btnProps = useLongPress(rest.start, rest.stop);
  const [hasFocus, setFocus] = useState(false);

  const onInputResult = useCallback(data => {
    onAudioInput(data);
  }, [onAudioInput]);

  useEffect(() => {
    socket.on(audioInputResult, onInputResult);
    return () => {
      socket.off(audioInputResult, onInputResult);
    }
  }, [onInputResult]);

  useEffect(() => {
    if (data) emitAudioInput(data);
  }, [data]);

  const onFocus = useCallback(() => setFocus(true), []);
  const onBlur = useCallback(() => setFocus(false), []);

  const {isPreparing, isRecording, isProcessing} = rest;
  const placeholder = useMemo(() => {
    if (isPreparing) {
      return 'Preparing...';
    } else if (isRecording) {
      return 'Recording...';
    } else if (isProcessing) {
      return 'Processing...';
    }
    return null;
  }, [isProcessing, isPreparing, isRecording]);

  return { 
    hasFocus, onFocus, onBlur, placeholder,
    toggle, btnProps, ...rest
  };
}