import React from 'react';
import { connect } from 'react-redux';
import Message from './Message';

function MessagesList({
  messages = []
}) {
  const n = messages.length - 1;

  return messages.map((message, k) => (
    <Message
      scrollIntoView={n === k}
      key={message.id}
      {...message}
    />
  ));
}

export default connect(
  state => ({
    messages: state.messages
  })
)(MessagesList);