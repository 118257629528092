import React from 'react';
import { connect } from 'react-redux';
import { Hub } from '@aws-amplify/core';
import {
  withAuthenticator
} from 'aws-amplify-react';
import SignIn from 'components/Auth/SignIn';
import ForgotPassword from 'components/Auth/ForgotPassword';
import { authStatusChanged, authError } from 'actions/auth';
import { SIGNED_OUT } from 'store/constants';
import App from 'containers/App';

const _Authenticator = withAuthenticator(App, false, [
  <SignIn />,
  <ForgotPassword />
  // <ConfirmSignIn/>,
  // <VerifyContact/>,
  // <RequireNewPassword/>
]);

class Authenticator extends _Authenticator {
  componentDidMount() {
    Hub.listen(SIGNED_OUT, () => {
      this.handleAuthStateChange(SIGNED_OUT);
    });
  }

  handleAuthStateChange(state, data) {
    super.handleAuthStateChange(state, data);
    this.props.authStatusChanged(state, data);
  }

  render() {
    return super.render();
  }
}

const mapDispatchToProps = dispatch => ({
  authStatusChanged: (state, data) => {
    dispatch(authStatusChanged(state, data));
    return null;
  },
  errorMessage: err => {
    dispatch(authError(err));
    return null;
  }
});

export default connect(
  null,
  mapDispatchToProps
)(Authenticator);