import React from 'react';
import ImageZoom from 'components/ImageZoom';

function ImageMessage({
  id, url
}) {
  return (
    <ImageZoom
      className="w-full cursor-pointer"
      src={url}
      alt={`message-${id}`}
    />
  );
}

export default React.memo(ImageMessage);